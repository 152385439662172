export default function IconDisney({ fillColor }) {
  return (
    <svg fill="#000000" height="54" width="54" version="1.1" id="Capa_1"
    viewBox="0 0 211.944 211.944">
 <path d="M2.031,171.72c-1.083,0-1.973-0.864-1.999-1.952C0.012,168.913,0,168.054,0,167.194C0,108.761,47.539,61.222,105.972,61.222
   c0.242,0,0.484,0.001,0.727,0.003l7.463-17.005h-3.434c-1.104,0-2-0.896-2-2s0.896-2,2-2h6.417c0.023,0.001,0.049,0,0.072,0
   c0.002,0,0.004,0,0.006,0c0.789,0,1.505,0.464,1.826,1.186l9.96,22.328c48.179,10.681,82.935,53.862,82.935,103.46
   c0,0.861-0.012,1.719-0.033,2.575c-0.026,1.104-0.945,2.023-2.048,1.95c-1.104-0.026-1.978-0.943-1.95-2.048
   c0.02-0.823,0.031-1.649,0.031-2.478c0-48.127-34.01-89.977-80.975-99.808h-0.579v20.265l1.228,0.613
   c0.678,0.339,1.105,1.031,1.105,1.789c0,0.031,0.019,3.408,0.989,12.794c0.244,2.36,0.772,4.328,1.412,5.942l3.61-12.569h-2.762
   c-1.104,0-2-0.896-2-2s0.896-2,2-2h5.422c0.957,0,1.757,0.672,1.954,1.57l4.792,16.195c0.108,0.368,0.109,0.759,0.002,1.128
   c-0.008,0.028-0.98,3.518,0,6.879c0.41,1.407,1.263,2.583,2.086,3.458l4.762-14.772h-2.726c-1.104,0-2-0.896-2-2s0.896-2,2-2h5.422
   c0.615,0,1.166,0.277,1.532,0.715c0.167,0.188,0.301,0.41,0.389,0.657c0.025,0.072,2.598,7.315,4.598,13.376
   c0.95,2.881,1.899,5.567,2.641,7.609c0.351-1.212,0.804-2.972,1.214-4.644h-1.254c-1.104,0-2-0.896-2-2s0.896-2,2-2h3.714
   c0.96,0,1.762,0.676,1.955,1.578c0.474,1.21,3.869,9.874,6.578,16.027c2.958,6.716,6.077,12.675,6.108,12.734
   c0.325,0.619,0.302,1.364-0.061,1.964c-0.361,0.599-1.011,0.965-1.711,0.965h-4.058c-0.048,1.862-0.109,4.862-0.109,8.06
   c0,4.869,2.647,11.155,2.674,11.218c0.263,0.618,0.197,1.326-0.173,1.886s-0.997,0.896-1.668,0.896h-52.5
   c-0.605,0-1.179-0.274-1.559-0.747c-0.38-0.472-0.524-1.091-0.395-1.683c0.015-0.069,1.568-7.787-3.465-16.94
   c-4.787-8.702-14.722-7.494-14.819-7.481c-0.223,0.029-0.446,0.021-0.664-0.023c-0.211-0.037-5.718-0.948-12.764,5.364
   c-7.017,6.286-4.326,18.951-4.298,19.078c0.13,0.592-0.015,1.212-0.394,1.685c-0.38,0.472-0.953,0.748-1.559,0.748h-54.5
   c-0.586,0-1.143-0.257-1.523-0.703c-0.38-0.447-0.545-1.037-0.451-1.616c0-0.002,0.916-5.662,1.748-10.989
   c0.526-3.368,0.852-7.325,1.015-9.691h-3.873c-0.692,0-1.336-0.358-1.7-0.947c-0.365-0.589-0.399-1.324-0.09-1.944
   c0.027-0.054,2.743-5.501,5.88-11.088c2.019-3.596,4.559-8.46,6.138-11.521h-3.103c-1.104,0-2-0.896-2-2s0.896-2,2-2h6.417
   c0.96,0,1.762,0.677,1.956,1.579l6.481,14.921h2.343c1.195-6.806,2.341-13.855,2.345-14.834c0-0.654-0.907-2.233-1.721-3.241
   c-0.521-0.645-0.592-1.546-0.176-2.263c0.002-0.003,2.414-4.16,5.24-9.148c1.586-2.799,3.266-5.995,4.42-8.232h-3.258
   c-1.104,0-2-0.896-2-2s0.896-2,2-2h6.417c0.427,0,0.822,0.134,1.146,0.361c0.334,0.211,0.606,0.521,0.769,0.899
   c0.028,0.065,2.826,6.555,5.091,10.76c2.284,4.242,4.601,8.136,4.624,8.175c0.029,0.049,0.056,0.099,0.081,0.149l5.354-12.893
   c0.328-0.791,1.117-1.295,1.978-1.229c0.854,0.057,1.578,0.649,1.801,1.477l2.982,11.075c0.192-2.133,0.392-5.352,0.105-7.452
   c-0.403-2.957-2.462-5.219-2.483-5.241c-0.517-0.561-0.676-1.373-0.401-2.084l5.91-15.278h-3.748c-1.104,0-2-0.896-2-2s0.896-2,2-2
   h6.417c0.93,0,1.712,0.635,1.936,1.495c0.017,0.037,0.032,0.073,0.047,0.111l4.684,12.009l2.478-6.041
   c0.289-0.704,0.951-1.183,1.71-1.236c0.769-0.037,1.482,0.329,1.866,0.984l0.095,0.162c0.351-0.302,0.807-0.484,1.305-0.484h0.333
   V67.386h-0.667c-0.676,0-1.305-0.341-1.675-0.906c-0.245-0.376-0.354-0.818-0.318-1.254C49.25,65.715,4,111.268,4,167.194
   c0,0.829,0.012,1.654,0.032,2.479c0.026,1.104-0.848,2.021-1.952,2.047C2.063,171.72,2.047,171.72,2.031,171.72z M125.805,167.72
   h47.373c-0.865-2.57-1.955-6.502-1.955-10c0-5.308,0.167-10.083,0.168-10.13c0.038-1.077,0.922-1.93,1.999-1.93h2.739
   c-1.213-2.43-3.002-6.112-4.736-10.052c-1.456-3.307-3.105-7.32-4.402-10.542c-0.351,1.28-0.691,2.407-0.94,2.966
   c-0.863,1.942-2.333,3.03-2.498,3.147c-0.503,0.359-1.142,0.468-1.737,0.289c-0.592-0.178-1.069-0.62-1.291-1.197
   c-0.021-0.054-2.067-5.381-4.117-11.592c-0.845-2.561-1.793-5.335-2.605-7.684l-4.675,14.503c-0.188,0.583-0.632,1.048-1.206,1.262
   c-0.572,0.214-1.214,0.152-1.737-0.167c-0.184-0.111-4.512-2.79-5.88-7.48c-1.047-3.59-0.462-7.126-0.151-8.513l-2.733-9.236
   l-2.363,8.229c0.381,0.675,0.832,1.612,0.974,2.465c0.185,1.105,0.194,3.938,0.194,4.494c0,0.745-0.414,1.429-1.074,1.773
   c-0.66,0.344-1.458,0.293-2.069-0.133c-0.255-0.178-6.264-4.477-7.346-14.935c-0.677-6.546-0.9-10.22-0.975-11.952l-1.264-0.632
   c-0.678-0.339-1.105-1.031-1.105-1.789v-23.5c0-1.104,0.896-2,2-2h0.084l-7.236-16.223l-7.136,16.259
   c0.924,0.177,1.621,0.989,1.621,1.964V87.72c0,1.104-0.896,2-2,2h-0.333v2.666c0,0.902-0.604,1.693-1.477,1.93
   c-0.87,0.241-1.792-0.14-2.249-0.919l-1.082-1.847l-2.842,6.929c-0.311,0.757-1.046,1.296-1.868,1.241
   c-0.817-0.007-1.549-0.512-1.846-1.273l-4.629-11.869l-4.561,11.791c0.915,1.252,2.313,3.563,2.701,6.414
   c0.53,3.885-0.295,10.416-0.331,10.691c-0.128,0.998-0.978,1.746-1.984,1.746h-3.167c-0.904,0-1.696-0.606-1.931-1.48l-1.974-7.33
   l-5.915,14.243c-0.348,0.838-1.221,1.34-2.116,1.215c-0.899-0.121-1.604-0.833-1.716-1.733l-0.782-6.254
   c-0.634-1.076-2.575-4.398-4.494-7.962c-1.203-2.233-2.518-5.013-3.531-7.229c-1.137,2.188-2.63,5.007-4.052,7.517
   c-1.948,3.437-3.699,6.48-4.606,8.053c0.781,1.167,1.783,2.985,1.783,4.627c0,1.913-2.248,14.65-2.698,17.184
   c-0.17,0.955-1,1.65-1.969,1.65h-5.333c-0.796,0-1.517-0.473-1.834-1.203l-5.483-12.625c-1.6,3.089-3.903,7.479-5.772,10.808
   c-1.681,2.993-3.238,5.943-4.313,8.021h2.735c0.548,0,1.072,0.225,1.449,0.621c0.377,0.397,0.576,0.932,0.548,1.479
   c-0.014,0.276-0.348,6.833-1.188,12.209l0,0c-0.502,3.213-1.034,6.546-1.378,8.691h49.809c-0.546-4.571-0.796-14.469,5.926-20.489
   c7.675-6.878,14.267-6.566,15.875-6.36c2.001-0.187,12.91-0.695,18.545,9.552C125.701,157.801,126.011,164.338,125.805,167.72z"/>
 </svg>
  );
}
