import * as React from "react";
import { IconButton, Box } from "@mui/material";
import IconClose from "../Icon/IconClose";
import Typography from "@mui/material/Typography";
import "./ServiceModal1.css";
import Grid from "@mui/material/Grid";
import { Fade } from "react-reveal";

const TitleModal = ({ title, subtitle, handleClose }) => {
  return (
    <Fade bottom duration={1000} distance="40px">
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
          <Grid container spacing={3}>
            <Grid item xs="auto">
              <Typography variant="h5" style={{ width: "auto" }}>
                {title}
              </Typography>
            </Grid>

            <Grid item xs>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  onClick={handleClose}
                  sx={{ width: 42, height: 42 }}
                >
                  <IconClose />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

export default TitleModal;
