import React from "react";
import { Headings } from "../../components";
import Grid from "@mui/material/Unstable_Grid2";
import "./Services.css";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { Fade } from "react-reveal";
import { useTranslation } from "react-i18next";
import Children1 from "../../components/ServiceCard/Children1";
import Children2 from "../../components/ServiceCard/Children2";
import Children3 from "../../components/ServiceCard/Children3";

const data = [
  {
    urlImage: "card_las_vegas",
    title: "service_1_title",
    description: "service_1_description",
  },
  {
    urlImage: "card_corporate_events_and_incentive",
    title: "service_2_title",
    description: "service_2_description",
  },
  {
    urlImage: "card_trade_show_event",
    title: "service_3_title",
    description: "service_3_description",
  },
];

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="section-padding" id="services">
        <div className="d-block pt-md-2">
          <Headings title={t("menu_services")} text="" />
          <Grid
            justifyContent="center"
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Fade bottom duration={1000} distance="40px">
              <ServiceCard
                urlImage={data[0].urlImage}
                title={t(data[0].title)}
                description={t(data[0].description)}
                children={<Children1 />}
              />
            </Fade>
            <Fade bottom duration={2000} distance="40px">
              <ServiceCard
                urlImage={data[1].urlImage}
                title={t(data[1].title)}
                description={t(data[1].description)}
                children={<Children2 />}
              />
            </Fade>
            <Fade bottom duration={3000} distance="40px">
              <ServiceCard
                urlImage={data[2].urlImage}
                title={t(data[2].title)}
                description={t(data[2].description)}
                children={<Children3 />}
              />
            </Fade>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Services;
