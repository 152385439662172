import React, { useEffect } from "react";
import "./Hero.css";
import { Fade } from "react-reveal";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { images } from "../../constants";
import ProgressiveImage from "react-progressive-image";

const colortheme = createTheme({
  palette: {
    primary: { main: "#FFFFFF", contrastText: "#000" },
    secondary: { main: "#FFFFFF", contrastText: "#000" },
  },
});

const Hero = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-0RF462RWPT";
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-0RF462RWPT");

    return () => {
      // Cleanup function to remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
<div
  className="hero-background"
  style={{
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <ProgressiveImage 
    src={images.skyline_home}
    placeholder={images.skyline_home_low} 
  >
    {(src) => (
      <img
        src={src}
        alt="an image"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
    )}
  </ProgressiveImage>

      <div className="overlay"></div>
      <div className="section-padding" id="home">
        <div className="container">
          <Box mt={0} ml={9} mr={9} mb={68}>
            <Box height={30}></Box>
            <ThemeProvider theme={colortheme}>
              <Fade bottom duration={1000} distance="40px">
                <Typography
                  variant="h5"
                  color="white"
                  align="center"
                  gutterBottom
                  margin="normal"
                  mb={1}
                  style={{ fontFamily: "MontserratSemiBold", fontSize: "24px" }}
                >
                  {t("home_desciption")}
                </Typography>
              </Fade>
              <Fade bottom duration={2000} distance="40px">
                <Typography
                  variant="h5"
                  color="white"
                  align="center"
                  gutterBottom
                  margin="normal"
                  mb={1}
                  mt={2}
                  style={{ fontFamily: "MontserratSemiBold", fontSize: "22px" }}
                >
                  {t("home_desciption_2")}
                </Typography>
              </Fade>
              <Fade bottom duration={3000} distance="40px">
                <Typography
                  variant="h5"
                  color="white"
                  align="center"
                  mt={2}
                  style={{ fontFamily: "MontserratSemiBold", fontSize: "22px" }}
                  gutterBottom
                >
                  {t("home_desciption_3")}
                </Typography>
              </Fade>
            </ThemeProvider>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Hero;
