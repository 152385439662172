export default function IconShopping({ fillColor }) {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 -0.5 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4308 12L18.4408 16C17.6168 18.913 16.9408 19.748 15.4698 19.946C15.1413 19.9859 14.8106 20.0039 14.4798 20H10.5198C10.1889 20.0039 9.85819 19.9859 9.52975 19.946C8.06375 19.746 7.38275 18.913 6.55875 16L5.56875 12C5.07575 9.014 7.32175 8.009 9.49975 8V7.125C9.46572 5.43382 10.8086 4.03499 12.4998 4C14.1909 4.03499 15.5338 5.43382 15.4998 7.125V8C17.6778 8.009 19.9228 9.013 19.4308 12Z"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4998 8.75C15.914 8.75 16.2498 8.41421 16.2498 8C16.2498 7.58579 15.914 7.25 15.4998 7.25V8.75ZM9.49976 7.25C9.08554 7.25 8.74976 7.58579 8.74976 8C8.74976 8.41421 9.08554 8.75 9.49976 8.75V7.25ZM16.2498 10C16.2498 9.58579 15.914 9.25 15.4998 9.25C15.0855 9.25 14.7498 9.58579 14.7498 10H16.2498ZM14.7498 11C14.7498 11.4142 15.0855 11.75 15.4998 11.75C15.914 11.75 16.2498 11.4142 16.2498 11H14.7498ZM10.2498 10C10.2498 9.58579 9.91397 9.25 9.49976 9.25C9.08554 9.25 8.74976 9.58579 8.74976 10H10.2498ZM8.74976 11C8.74976 11.4142 9.08554 11.75 9.49976 11.75C9.91397 11.75 10.2498 11.4142 10.2498 11H8.74976ZM15.4998 7.25H9.49976V8.75H15.4998V7.25ZM14.7498 10V11H16.2498V10H14.7498ZM8.74976 10V11H10.2498V10H8.74976Z"
        fill="#000000"
      />
    </svg>
  );
}
