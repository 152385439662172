import Typography from "@mui/material/Typography";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { images } from "../../constants";
import TitleModal from "./TitleModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlideImg from "./SlideImg";

const ServiceModal2 = ({ showContent, setOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    paddingBottom: 20,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    cssEase: "linear",
    adaptiveHeight: true,
    height: "inherit",
    arrows: true,
    margin: 5,
  };

  return (
    <Box style={style}>
      {showContent ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "#f6911e55",
            }}
          >
            <Box sx={{ margin: 2 }}>
              {
                <TitleModal
                  title={t("service_2_subtitle_1")}
                  subtitle={""}
                  handleClose={handleClose}
                />
              }
            </Box>
          </Box>

          <Box sx={{ margin: 2 }}>
            {
              <>
                <Typography variant="h7" paragraph>
                  {t("service_2_subtitle_1_description_1")}
                </Typography>
                <Typography variant="h7" paragraph>
                  {t("service_2_subtitle_1_description_2")}
                </Typography>
              </>
            }
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "#f6911e55",
            }}
          >
            <Box sx={{ margin: 2 }}>
              {
                <TitleModal
                  title={t("service_2_subtitle_2")}
                  subtitle={""}
                  handleClose={handleClose}
                />
              }
            </Box>
          </Box>

          <Box sx={{ margin: 2 }}>
            {
              <>
                <Typography variant="h7" paragraph>
                  {t("service_2_subtitle_2_description_1")}
                </Typography>
                <Typography variant="h7" paragraph>
                  {t("service_2_subtitle_2_description_2")}
                </Typography>
              </>
            }
          </Box>
        </>
      )}

      {showContent ? (
        <Slider {...settings} adaptiveHeight>
          <div>
            <SlideImg img={images.event_service_1} />
          </div>
          <div>
            <SlideImg img={images.event_service_2} />
          </div>
          <div>
            <SlideImg img={images.event_service_3} />
          </div>
          <div>
            <SlideImg img={images.event_service_4} />
          </div>
          <div>
            <SlideImg img={images.event_service_5} />
          </div>
          <div>
            <SlideImg img={images.event_service_6} />
          </div>
          <div>
            <SlideImg img={images.event_service_7} />
          </div>
        </Slider>
      ) : (
        <Slider {...settings} adaptiveHeight>
          <div>
            <SlideImg img={images.incentive_service_1} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_2} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_3} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_4} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_5} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_6} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_7} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_8} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_9} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_10} />
          </div>
          <div>
            <SlideImg img={images.incentive_service_11} />
          </div>
        </Slider>
      )}
    </Box>
  );
};

export default ServiceModal2;
