import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import ServiceButtonCard from "../ServiceButtonCard";
import * as React from "react";
import CardCities from "./ServiceModal1";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Children1 = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = (screen) => {
    setShowModalCitie(screen);
    setOpen(true);
  }
  const [showModalCitie, setShowModalCitie] = useState("LV");
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <ServiceButtonCard
        text={t("service_1_subtitle_1")}
        onClick={() => handleOpen("LV")}
      />
      <ServiceButtonCard
        text={t("service_1_subtitle_2")}
        onClick={() => handleOpen("LA")}
      />
      <ServiceButtonCard
        text={t("service_1_subtitle_3")}
        onClick={() => handleOpen("SF")}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CardCities showModalCitie={showModalCitie} setOpen={setOpen}/>
        </Box>
      </Modal>
    </Box>
  );
};
export default Children1;
