import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import * as React from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import IconTransfer from "../Icon/IconTransfer";
import IconTour from "../Icon/IconTour";
import IconHelicopter from "../Icon/IconHelicopter";
import IconCanyon from "../Icon/IconCanyon";
import IconRoute66 from "../Icon/IconRoute66";
import IconBridgeEngineering from "../Icon/IconBridgeEngineering";
import IconMount from '../Icon/IconMount';
import IconCircus from "../Icon/IconCircus";
import IconSphere from "../Icon/IconSphere";
import IconOther from "../Icon/IconOther";
import IconHollywood from "../Icon/IconHollywood";
import IconDisney from "../Icon/IconDisney";
import IconBeach from "../Icon/IconBeach";
import IconPrison from "../Icon/IconPrison";
import IconValley from "../Icon/IconValley";
import IconShopping from "../Icon/IconShopping";

const CardLine = ({ data }) => {
  return (
    <Card sx={{ maxWidth: 345, height: 300, marginBottom:5 }}>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            {data.map((item, index) => (
              <div key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  {item.icon === "icon_transfer" && <IconTransfer />}
                  {item.icon === "icon_tour" && <IconTour />}
                  {item.icon === "icon_helicopter" && <IconHelicopter />}
                  {item.icon === "icon_canyon" && <IconCanyon />}
                  {item.icon === "icon_route66" && <IconRoute66 />}
                  {item.icon === "icon_bridge" && <IconBridgeEngineering />}
                  {item.icon === "icon_mount" && <IconMount/>}
                  {item.icon === "icon_circus" && <IconCircus/>}
                  {item.icon === "icon_sphere" && <IconSphere/>}
                  {item.icon === "icon_other" && <IconOther/>}
                  {item.icon === "icon_hollywood" && <IconHollywood/>}
                  {item.icon === "icon_disney" && <IconDisney/>}
                  {item.icon === "icon_beach" && <IconBeach/>}
                  {item.icon === "icon_prison" && <IconPrison/>}
                  {item.icon === "icon_valley" && <IconValley/>}
                  {item.icon === "icon_shopping" && <IconShopping/>}

                </Box>

                {item.name.split(":").map((i, key) => {
                  const [before, after] = i.split(":");

                  return (
                    <Box key={key}>
                      <Typography
                        variant="h8"
                        paragraph
                        sx={{ textAlign: "center" }}
                      >
                        {before}
                      </Typography>
                      <Typography variant="h8" paragraph>
                        {after}
                      </Typography>
                    </Box>
                  );
                })}
              </div>
            ))}
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
};
export default CardLine;
