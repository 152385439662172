export default function IconCanyon({ fillColor }) {
  return (
    <svg
      fill="#000000"
      height="54"
      width="54"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M503.83,369.021h-89.872V175.66c0-4.506,3.665-8.17,8.17-8.17c4.506,0,8.17,3.665,8.17,8.17v168.851
			c0,4.513,3.657,8.17,8.17,8.17s8.17-3.657,8.17-8.17v-33.848h24.511c4.513,0,8.17-3.657,8.17-8.17v-66.918
			c0-13.515,10.996-24.511,24.511-24.511c4.513,0,8.17-3.657,8.17-8.17s-3.657-8.17-8.17-8.17
			c-22.526,0-40.851,18.325-40.851,40.851v58.748h-16.34V175.66c0-13.515-10.996-24.511-24.511-24.511
			c-13.515,0-24.511,10.996-24.511,24.511v193.362h-18.622l-19.926-59.776c-1.112-3.337-4.234-5.586-7.751-5.586h-19.064v-57.192
			c0-19.728-14.057-36.233-32.681-40.029v-30.78c0-13.515-10.996-24.511-24.511-24.511s-24.511,10.996-24.511,24.511v62.638h-21.787
			v-57.191c0-19.727-14.058-36.234-32.681-40.031v-14.437c0-22.526-18.325-40.851-40.851-40.851h-54.468
			c-22.526,0-40.851,18.325-40.851,40.851v36.227c-18.624,3.795-32.681,20.3-32.681,40.029v57.191H8.17
			c-4.512,0-8.17,3.657-8.17,8.17s3.658,8.17,8.17,8.17h27.234c4.512,0,8.17-3.657,8.17-8.17v-65.362
			c0-10.651,6.831-19.733,16.34-23.105v66.68c0,4.513,3.658,8.17,8.17,8.17c4.512,0,8.17-3.657,8.17-8.17v-119.83
			c0-13.515,10.995-24.511,24.511-24.511h54.468c13.516,0,24.511,10.996,24.511,24.511v21.787c0,4.513,3.658,8.17,8.17,8.17
			c13.516,0,24.511,10.996,24.511,24.511v65.362c0,4.513,3.658,8.17,8.17,8.17h29.957v89.872c0,4.513,3.658,8.17,8.17,8.17
			c4.513,0,8.17-3.657,8.17-8.17V175.66c0-4.506,3.665-8.17,8.17-8.17c4.506,0,8.17,3.665,8.17,8.17v70.808
			c0,4.513,3.657,8.17,8.17,8.17c4.513,0,8.17-3.657,8.17-8.17v-23.105c9.509,3.373,16.34,12.455,16.34,23.105v65.362
			c0,4.513,3.657,8.17,8.17,8.17h21.346l16.34,49.021H8.17c-4.512,0-8.17,3.657-8.17,8.17c0,4.513,3.658,8.17,8.17,8.17h364.908
			c0.053,0,130.752,0,130.752,0c4.513,0,8.17-3.657,8.17-8.17C512,372.678,508.343,369.021,503.83,369.021z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M68.085,270.979c-4.512,0-8.17,3.657-8.17,8.17v65.362c0,4.513,3.658,8.17,8.17,8.17c4.512,0,8.17-3.657,8.17-8.17
			v-65.362C76.255,274.636,72.597,270.979,68.085,270.979z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M348.596,20.426c-25.529,0-46.298,20.769-46.298,46.298c0,25.529,20.769,46.298,46.298,46.298
			s46.298-20.769,46.298-46.298C394.894,41.194,374.125,20.426,348.596,20.426z M348.596,96.681
			c-16.518,0-29.957-13.439-29.957-29.957c0-16.518,13.439-29.957,29.957-29.957c16.518,0,29.957,13.439,29.957,29.957
			C378.553,83.241,365.114,96.681,348.596,96.681z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle cx="81.702" cy="450.723" r="8.17" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="49.021" cy="483.404" r="8.17" />
        </g>
      </g>
      <g>
        <g>
          <circle cx="430.298" cy="461.617" r="8.17" />
        </g>
      </g>
    </svg>
  );
}
