export default function IconPrison({ fillColor }) {
  return (
    <svg height="54" width="54" version="1.1" id="Layer_1"
    viewBox="0 0 512 512">
 <g>
   <g>
     <path d="M139.229,226.158c-12.517,0-22.7,10.184-22.7,22.702c0,9.546,5.93,17.717,14.292,21.068v15.926
       c0,4.643,3.763,8.408,8.408,8.408s8.408-3.765,8.408-8.408v-15.926c8.362-3.35,14.292-11.522,14.292-21.068
       C161.93,236.342,151.746,226.158,139.229,226.158z M139.229,254.744c-3.245,0-5.884-2.64-5.884-5.884
       c0-3.244,2.639-5.886,5.884-5.886s5.884,2.64,5.884,5.886S142.475,254.744,139.229,254.744z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M413.313,0H98.687c-4.645,0-8.408,3.765-8.408,8.408v495.184c0,4.643,3.763,8.408,8.408,8.408h314.626
       c4.645,0,8.408-3.765,8.408-8.408V8.408C421.721,3.765,417.958,0,413.313,0z M107.095,77.342h40.45v112.663h-40.45V77.342z
        M222.652,242.284c4.645,0,8.408-3.765,8.408-8.408V77.342h49.884v171.517h-91.269v-50.444c0-4.643-3.763-8.408-8.408-8.408
       h-16.906V77.342h49.883v156.534C214.244,238.519,218.007,242.284,222.652,242.284z M222.652,273.674
       c-4.645,0-8.408,3.765-8.408,8.408v123.16h-49.883v-80.714h16.906c4.645,0,8.408-3.765,8.408-8.408v-50.444h91.269v139.567H231.06
       v-123.16C231.06,277.438,227.296,273.674,222.652,273.674z M172.859,206.821v100.89h-16.819c-0.029,0-0.057-0.005-0.086-0.005
       c-0.029,0-0.057,0.005-0.086,0.005h-48.77v-100.89h48.839c0.006,0,0.011,0.001,0.017,0.001s0.011-0.001,0.017-0.001H172.859z
        M107.095,324.527h40.45v80.714h-40.45V324.527z M404.905,495.184h-297.81v0v-73.127h207.766c4.645,0,8.408-3.765,8.408-8.408
       s-3.763-8.408-8.408-8.408H297.76V265.675h49.884v139.567h-8.679c-4.645,0-8.408,3.765-8.408,8.408s3.763,8.408,8.408,8.408
       h65.941V495.184z M404.905,405.241h-40.446V265.675h40.446V405.241z M404.905,248.859h-40.446v-81.126
       c0-4.643-3.763-8.408-8.408-8.408c-4.645,0-8.408,3.765-8.408,8.408v81.126H297.76V77.342h49.884v62.365
       c0,4.643,3.763,8.408,8.408,8.408c4.645,0,8.408-3.765,8.408-8.408V77.342h40.446V248.859z M404.905,60.526h-297.81v-43.71h297.81
       V60.526z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M256.006,464.097c-4.652,0-8.408,3.756-8.408,8.408c0,4.641,3.756,8.397,8.408,8.397c4.641,0,8.408-3.756,8.408-8.397
       C264.414,467.853,260.647,464.097,256.006,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M223.842,464.097c-4.641,0-8.408,3.756-8.408,8.408c0,4.641,3.767,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C232.25,467.853,228.484,464.097,223.842,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M159.526,464.097c-4.641,0-8.408,3.756-8.408,8.408c0,4.641,3.767,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C167.934,467.853,164.168,464.097,159.526,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M288.158,464.097c-4.641,0-8.408,3.756-8.408,8.408c0,4.641,3.767,8.397,8.408,8.397s8.408-3.756,8.408-8.397
       C296.566,467.853,292.798,464.097,288.158,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M352.473,464.097c-4.641,0-8.397,3.756-8.397,8.408c0,4.641,3.757,8.397,8.397,8.397c4.652,0,8.408-3.756,8.408-8.397
       C360.881,467.853,357.125,464.097,352.473,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M320.321,464.097c-4.642,0-8.408,3.756-8.408,8.408c0,4.641,3.766,8.397,8.408,8.397c4.641,0,8.408-3.756,8.408-8.397
       C328.729,467.853,324.962,464.097,320.321,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M191.679,464.097c-4.642,0-8.397,3.756-8.397,8.408c0,4.641,3.756,8.397,8.397,8.397c4.651,0,8.408-3.756,8.408-8.397
       C200.087,467.853,196.332,464.097,191.679,464.097z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M256.006,30.818c-4.652,0-8.408,3.767-8.408,8.419c0,4.641,3.756,8.397,8.408,8.397c4.641,0,8.408-3.756,8.408-8.397
       C264.414,34.585,260.647,30.818,256.006,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M223.842,30.818c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.767,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C232.25,34.585,228.485,30.818,223.842,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M288.158,30.818c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.767,8.397,8.408,8.397c4.641,0,8.408-3.756,8.408-8.397
       C296.566,34.585,292.799,30.818,288.158,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M320.321,30.818c-4.642,0-8.408,3.767-8.408,8.419c0,4.641,3.766,8.397,8.408,8.397c4.641,0,8.408-3.756,8.408-8.397
       C328.729,34.585,324.962,30.818,320.321,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M159.526,30.818c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.768,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C167.934,34.585,164.168,30.818,159.526,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M352.473,30.818c-4.641,0-8.397,3.767-8.397,8.419c0,4.641,3.756,8.397,8.397,8.397c4.652,0,8.408-3.756,8.408-8.397
       C360.881,34.585,357.125,30.818,352.473,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M191.679,30.818c-4.642,0-8.397,3.767-8.397,8.419c0,4.641,3.754,8.397,8.397,8.397c4.651,0,8.408-3.756,8.408-8.397
       C200.087,34.585,196.33,30.818,191.679,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M127.364,30.818c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.767,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C135.772,34.585,132.006,30.818,127.364,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M384.636,30.818c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.768,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C393.044,34.585,389.279,30.818,384.636,30.818z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M384.636,464.086c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.768,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C393.044,467.853,389.279,464.086,384.636,464.086z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M127.364,464.097c-4.641,0-8.408,3.767-8.408,8.419c0,4.641,3.767,8.397,8.408,8.397c4.642,0,8.408-3.756,8.408-8.397
       C135.772,467.864,132.006,464.097,127.364,464.097z"/>
   </g>
 </g>
 </svg>
  );
}
