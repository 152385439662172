import React, { useState } from "react";
import { Headings } from "../../components";
import "./Contact.css";
import { TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import "react-international-phone/style.css";
import { MuiPhone } from "../../components/MuiPhone";
import FormHelperText from "@mui/material/FormHelperText";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const { t } = useTranslation();

  const [errorFullName, setErrorFullName] = useState(false);
  const [helperTextFullName, setHelperTextFullName] = useState("");
  const [fullName, setFullName] = React.useState("");

  const [errorEmail, setErrorEmail] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [email, setEmail] = React.useState("");

  const [phone, setPhone] = useState("");

  const [errorCompany, setErrorCompany] = useState(false);
  const [helperTextCompany, setHelperTextCompany] = useState("");
  const [company, setCompany] = useState("");

  const [errorSubject, setErrorSubject] = useState(false);
  const [helperTextSubject, setHelperTextSubject] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectIndex, setSubjectIndex] = useState("");

  const [errorMessage, setErrorMessage] = useState(false);
  const [helperTextMessage, setHelperTextMessage] = useState("");
  const [message, setMessage] = useState("");

  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([true, false]);

  const selectOptions = [
    t("contact_us_label_subject_selector_single"),
    t("contact_us_label_subject_selector_group"),
    t("contact_us_label_subject_selector_event"),
    t("contact_us_label_subject_selector_travel"),
    t("contact_us_label_subject_selector_marketing"),
  ];

  const showToastMessage = () => {
    toast.success(t("contact_message_successful"), {
    });
  };

  const showToastMessageError = () => {
    toast.error(t("contact_message_successful_error"), {
    });
  };
  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
    const index = selectOptions.map((item) => item).indexOf(event.target.value);
    setSubjectIndex(index);
  };

  const handleChangeFullName = (e) => {
    setFullName(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeCompany = (e) => {
    setCompany(e.target.value);
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!fullName) {
      setErrorFullName(true);
      setHelperTextFullName(t("contact_us_error_full_name"));
      return;
    } else {
      setErrorFullName(false);
      setHelperTextFullName("");
    }

    if (!email) {
      setErrorEmail(true);
      setHelperTextEmail(t("contact_us_error_email"));
      return;
    } else {
      setErrorEmail(false);
      setHelperTextEmail("");
    }

    if (!company) {
      setErrorCompany(true);
      setHelperTextCompany(t("contact_us_error_company"));
      return;
    } else {
      setErrorCompany(false);
      setHelperTextCompany("");
    }

    if (!subject) {
      setErrorSubject(true);
      setHelperTextSubject(t("contact_us_error_subject"));
      return;
    } else {
      setErrorSubject(false);
      setHelperTextSubject("");
    }
    if (!message) {
      setErrorMessage(true);
      setHelperTextMessage(t("contact_us_error_message"));
      return;
    } else {
      setErrorMessage(false);
      setHelperTextMessage("");
    }

    if (!isChecked) {
      setError(true);
      setHelperText(t("contact_us_error_agree"));
      return;
    } else {
      setError(false);
      setHelperText("");
    }

    const formData = new FormData();
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("phone", phone);
    formData.append("company", company);
    formData.append("subject", subject);
    if (subjectIndex === 0) {
      formData.append("emailToSend", "reserva@bramertours.com");
    } else {
      formData.append("emailToSend", "grupos@bramertours.com");
    }

    setLoading(true);

    axios({
      url: "https://www.glauco.website/PHPMailer/send_email.php",
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((responseSubmit) => {
        console.log("sending...", responseSubmit);
        if (responseSubmit.data.response === 1) {
          console.log("Sended");
        } else {
          console.log(responseSubmit + "NOT Sended");
        }
        handleCleanFields();
      })
      .catch((errorSubmit) => {
        console.log("error...", errorSubmit);
        setLoading(false);
        showToastMessageError();
      });

    e.preventDefault();
    console.log("sended!");
  };

  const handleCleanFields = (e) => {
    setLoading(false);
    setFullName("");
    setEmail("");
    setPhone("");
    setCompany("");
    setSubject("");
    setMessage("");
    setIsChecked(false);
    showToastMessage();
  }

  return (
    <div className="section-padding" id="quote">
      <div
        className="contact-background"
        style={{
          display: "flex",
        }}
      >
        <div className="overlay-contact" />
        <div className="container">
          <Headings title={t("menu_contact_us")} text="" />

          <Typography variant="h7" paragraph>
            {t("contact_us_title")}
          </Typography>

          <TextField
            label={t("contact_us_label_full_name")}
            variant="outlined"
            name="full_name"
            value={fullName}
            onChange={handleChangeFullName}
            fullWidth
            margin="normal"
            error={errorFullName}
            helperText={helperTextFullName}
          />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label={t("contact_us_label_email")}
                variant="outlined"
                name="email"
                value={email}
                onChange={handleChangeEmail}
                fullWidth
                margin="normal"
                error={errorEmail}
                helperText={helperTextEmail}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPhone
                label={t("contact_us_label_phone")}
                fullWidth
                defaultCountry="us"
                value={phone}
                onChange={(phone) => setPhone(phone)}
                margin="normal"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label={t("contact_us_label_company")}
                variant="outlined"
                name="company"
                value={company}
                onChange={handleChangeCompany}
                fullWidth
                margin="normal"
                error={errorCompany}
                helperText={helperTextCompany}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl margin="normal" fullWidth error={errorSubject}>
                <InputLabel id="demo-simple-select-label">
                  {t("contact_us_label_subject")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subject}
                  label={t("contact_us_label_subject")}
                  onChange={handleChangeSubject}
                  error={errorSubject}
                  helperText={helperTextSubject}
                >
                  {selectOptions.map((option, index) => (
                    <MenuItem key={index} value={`${option}`}>
                      {option}{" "}
                    </MenuItem>
                  ))}
                </Select>
                {errorSubject && (
                  <FormHelperText>
                    {t("contact_us_error_subject")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <TextField
            label="Message"
            variant="outlined"
            name="message"
            value={message}
            onChange={handleChangeMessage}
            multiline
            rows={4}
            fullWidth
            margin="normal"
            error={errorMessage}
            helperText={helperTextMessage}
          />

          <FormControl
            required
            error={error}
            component="fieldset"
            sx={{ m: 3 }}
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    margin="normal"
                    onChange={handleChange}
                    checked={isChecked ? checked[0] : checked[1] }
                    sx={{
                      color: "#666666",
                      "&.Mui-checked": {
                        color: "#f6911e",
                      },
                    }}
                  />
                }
                label={t("contact_us_label_message_agreement")}
                error={error} 
                helperText={helperText}
              />
            </FormGroup>
            {error && (
              <FormHelperText>{t("contact_us_error_agree")}</FormHelperText>
            )}
          </FormControl>

          <Grid container>
            <Box
              mt={2}
              mb={5}
              display="flex"
              justifyContent="flex-end"
              width="100%"
            >
              <LoadingButton
                style={{
                  backgroundColor: "#f6911e",
                }}
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="center"
                variant="contained"
              >
                <span>{t("contact_us_button_submit")}</span>
              </LoadingButton>
              
            </Box>
          </Grid>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Contact;
