import * as React from "react";
import { Fade } from "react-reveal";
import { Box } from "@mui/material";

const SlideImg = ({img }) => {
  const imageStyle = {
    width: "90%",
    height: 350,
    objectFit: "cover",
    display: "block",
    margin: "auto",
  };

  return (
    <Fade bottom duration={2000} distance="40px">
      <Box component="img" src={img} alt="photo" sx={imageStyle} />
    </Fade>
  );
};
export default SlideImg;
