import logo  from '../assets/logo.png';

import services01  from '../assets/services01.png';
import services02  from '../assets/services02.png';
import services03  from '../assets/services03.png';
import services04  from '../assets/services04.png';
import services05  from '../assets/services05.png';
import services06  from '../assets/services06.png';

import watermark_simbol from '../assets/watermark_simbol.png';


import evento_corporativo from '../assets/evento_corporativo.webp';
import producao_evento from '../assets/producao_evento.webp';
import viagem_auto_padrao from '../assets/viagem_auto_padrao.webp';
import viagem_incentivo from '../assets/viagem_incentivo.webp';
import viagem_lazer from '../assets/viagem_lazer.webp';

import avatar_chatbot from '../assets/avatar_chatbot.png';
import skyline_home from '../assets/skyline_home.jpg';
import skyline_home_low from '../assets/skyline_home_low.png';

import skyline_home_2 from '../assets/skyline_home_2.png';
import card_corporate_events_and_incentive from '../assets/card_corporate_events_and_incentive.png';
import card_las_vegas from '../assets/card_las_vegas.png';
import card_trade_show_event from '../assets/card_trade_show_event.png';

import landing from '../assets/landing.png';
import landing_las_vegas from '../assets/landing_las_vegas.png';
import landing_los_angeles from '../assets/landing_los_angeles.png';
import landing_san_fracisco from '../assets/landing_san_fracisco.png';

import production_service_1 from '../assets/production_service_1.jpeg';
import production_service_2 from '../assets/production_service_2.jpeg';
import production_service_3 from '../assets/production_service_3.jpeg';
import production_service_4 from '../assets/production_service_4.jpeg';
import production_service_5 from '../assets/production_service_5.jpeg';

import event_service_1 from '../assets/event_service_1.jpeg';
import event_service_2 from '../assets/event_service_2.jpeg';
import event_service_3 from '../assets/event_service_3.jpeg';
import event_service_4 from '../assets/event_service_4.jpeg';
import event_service_5 from '../assets/event_service_5.jpeg';
import event_service_6 from '../assets/event_service_6.jpeg';
import event_service_7 from '../assets/event_service_7.jpeg';

import incentive_service_1 from '../assets/incentive_service_1.jpeg';
import incentive_service_2 from '../assets/incentive_service_2.jpeg';
import incentive_service_3 from '../assets/incentive_service_3.jpeg';
import incentive_service_4 from '../assets/incentive_service_4.jpeg';
import incentive_service_5 from '../assets/incentive_service_5.jpeg';
import incentive_service_6 from '../assets/incentive_service_6.jpeg';
import incentive_service_7 from '../assets/incentive_service_7.jpeg';
import incentive_service_8 from '../assets/incentive_service_8.jpeg';
import incentive_service_9 from '../assets/incentive_service_9.jpeg';
import incentive_service_10 from '../assets/incentive_service_10.jpeg';
import incentive_service_11 from '../assets/incentive_service_11.jpeg';


import cordenation_service_1 from '../assets/cordenation_service_1.jpeg';
import cordenation_service_2 from '../assets/cordenation_service_2.jpeg';
import cordenation_service_3 from '../assets/cordenation_service_3.jpeg';
import cordenation_service_4 from '../assets/cordenation_service_4.jpeg';
import cordenation_service_5 from '../assets/cordenation_service_5.jpeg';
import cordenation_service_6 from '../assets/cordenation_service_6.jpeg';
import cordenation_service_7 from '../assets/cordenation_service_7.jpeg';
import cordenation_service_8 from '../assets/cordenation_service_8.jpeg';

export default{
logo,
services01,
services02,
services03,
services04,
services05,
services06,
watermark_simbol,
evento_corporativo,
producao_evento,
viagem_auto_padrao,
viagem_incentivo,
viagem_lazer,
avatar_chatbot,
skyline_home,
skyline_home_low,
skyline_home_2,
card_corporate_events_and_incentive,
card_las_vegas,
card_trade_show_event,
landing_las_vegas,
landing_los_angeles,
landing_san_fracisco,
landing,
production_service_1,
production_service_2,
production_service_3,
production_service_4,
production_service_5,
event_service_1,
event_service_2,
event_service_3,
event_service_4,
event_service_5,
event_service_6,
event_service_7,
incentive_service_1,
incentive_service_2,
incentive_service_3,
incentive_service_4,
incentive_service_5,
incentive_service_6,
incentive_service_7,
incentive_service_8,
incentive_service_9,
incentive_service_10,
incentive_service_11,
cordenation_service_1,
cordenation_service_2,
cordenation_service_3,
cordenation_service_4,
cordenation_service_5,
cordenation_service_6,
cordenation_service_7,
cordenation_service_8,
};