export default function IconBeach({ fillColor }) {
  return (
    <svg fill="#000000" height="54" width="54" version="1.1" id="Capa_1"
    viewBox="0 0 441.303 441.303">
 <g>
   <path d="M437.396,258.832c-58.714-17.791-119.309-28.245-180.599-31.197c2.288-7.78,5.301-15.348,9.013-22.599
     c0.39,3.188,0.562,7.215,0.031,11.811c-0.348,3.018,1.815,5.746,4.833,6.094c0.215,0.024,0.427,0.037,0.638,0.037
     c2.756,0,5.134-2.067,5.457-4.87c0.507-4.397,0.49-8.39,0.222-11.823c2.405,2.664,4.693,6.07,6.337,10.388
     c0.836,2.195,2.925,3.545,5.142,3.545c0.65,0,1.312-0.116,1.955-0.361c2.839-1.081,4.265-4.258,3.185-7.097
     c-3.276-8.606-8.493-14.524-13.344-18.468c0.856-0.051,1.775-0.06,2.76-0.013c3.058,0.153,5.61-2.197,5.756-5.232
     c0.145-3.034-2.198-5.611-5.232-5.755c-6.785-0.328-11.781,1.208-15.047,2.776c-4.305-4.77-13.561-11.943-28.746-8.989
     c-2.981,0.58-4.929,3.467-4.349,6.449c0.579,2.981,3.463,4.929,6.448,4.349c5.302-1.031,9.453-0.207,12.566,1.213
     c-4.567,1.397-9.605,3.783-13.574,7.856c-2.12,2.176-2.074,5.658,0.102,7.778c1.069,1.042,2.454,1.561,3.838,1.561
     c1.431,0,2.861-0.556,3.939-1.662c2.033-2.087,4.636-3.501,7.224-4.464c-4.413,8.643-7.924,17.698-10.469,27.019
     c-7.376-0.244-14.761-0.379-22.153-0.405c-2.197-16.244-7.226-32.022-14.854-46.59c3.197,0.693,6.596,1.953,9.174,4.226
     c1.046,0.923,2.344,1.376,3.637,1.376c1.522,0,3.039-0.629,4.126-1.861c2.01-2.278,1.793-5.753-0.485-7.763
     c-3.794-3.348-8.419-5.278-12.758-6.381c2.906-1.018,6.572-1.47,11.069-0.596c2.987,0.581,5.869-1.368,6.448-4.349
     c0.58-2.982-1.367-5.869-4.349-6.449c-14.765-2.87-23.928,3.832-28.381,8.59c-5.87-2.53-16.748-5.582-28.712-0.797
     c-2.82,1.128-4.191,4.329-3.063,7.149c1.129,2.821,4.328,4.193,7.149,3.064c4.827-1.93,9.433-2.014,13.245-1.404
     c-6.125,4.761-13.037,12.671-15.949,25.19c-0.688,2.958,1.153,5.915,4.111,6.603c0.419,0.098,0.838,0.145,1.251,0.145
     c2.5,0,4.762-1.716,5.353-4.256c1.541-6.628,4.547-11.527,7.761-15.089c-0.269,3.436-0.287,7.433,0.221,11.835
     c0.323,2.804,2.701,4.87,5.457,4.87c0.21,0,0.423-0.012,0.638-0.037c3.018-0.348,5.181-3.077,4.833-6.094
     c-0.53-4.591-0.357-8.618,0.033-11.808c6.504,12.705,10.864,26.387,12.928,40.466c-70.722,0.793-140.757,11.552-208.305,32.02
     c-2.907,0.881-4.55,3.952-3.669,6.858c0.881,2.907,3.956,4.55,6.858,3.669c66.877-20.265,136.23-30.873,206.259-31.562
     c0.052,0.852,0.093,1.705,0.128,2.559c-26.488,1.781-50.175,16.095-64.145,39.059c-0.863,1.42-1.039,3.155-0.477,4.719
     s1.803,2.79,3.373,3.335c21.479,7.454,43.978,11.233,66.87,11.233s45.392-3.779,66.87-11.233c1.57-0.545,2.811-1.771,3.373-3.335
     s0.387-3.299-0.477-4.719c-8.802-14.47-21.465-25.503-36.253-32.133c0.39-2.94,0.879-5.864,1.468-8.766
     c61.132,2.764,121.576,13.103,180.122,30.843c0.531,0.161,1.068,0.238,1.597,0.238c2.36,0,4.542-1.531,5.262-3.907
     C441.946,262.783,440.303,259.713,437.396,258.832z M275.532,279.308c-18.235,5.573-37.183,8.395-56.428,8.395
     s-38.192-2.821-56.428-8.395c13.306-17.743,33.927-28.144,56.428-28.144c7.523,0,14.833,1.168,21.733,3.379
     c-0.228,3.261-0.349,6.534-0.349,9.814c0,3.038,2.463,5.5,5.5,5.5s5.5-2.462,5.5-5.5c0-1.782,0.05-3.56,0.123-5.337
     C260.892,263.823,269.075,270.698,275.532,279.308z M242.073,243.412c-5.662-1.642-11.547-2.686-17.579-3.075
     c-0.032-0.855-0.08-1.708-0.128-2.561c6.208,0.031,12.411,0.139,18.607,0.325C242.639,239.865,242.34,241.635,242.073,243.412z"/>
   <path d="M279.753,120.03c0.185,2.896,2.587,5.151,5.489,5.151h93.562c2.55,0,4.766-1.753,5.353-4.235
     c0.48-2.032,0.724-4.066,0.724-6.046c0-14.457-11.762-26.219-26.219-26.219c-3.792,0-7.467,0.805-10.851,2.358
     c-5.734-6.802-14.253-10.858-23.31-10.858c-11.381,0-21.867,6.495-27.054,16.46c-10.195,2.186-17.759,11.252-17.759,21.977
     c0,0.408,0.023,0.809,0.051,1.21L279.753,120.03z M301.519,107.159c2.155-0.124,4.039-1.497,4.816-3.511
     c2.876-7.457,10.177-12.467,18.166-12.467c6.993,0,13.485,3.788,16.944,9.886c0.781,1.376,2.116,2.35,3.666,2.671
     c1.548,0.323,3.162-0.039,4.426-0.991c2.665-2.006,5.82-3.067,9.124-3.067c8.15,0,14.825,6.441,15.202,14.5h-82.282
     C293.234,110.253,297.015,107.417,301.519,107.159z"/>
   <path d="M90.422,171.169c-4.88-4.48-11.218-6.976-17.992-6.976c-14.741,0-26.734,11.993-26.734,26.734c0,3.038,2.463,5.5,5.5,5.5
     h64.642c3.037,0,5.5-2.462,5.5-5.5c0-12.009-9.77-21.778-21.778-21.778C96.398,169.149,93.273,169.85,90.422,171.169z
      M108.826,185.427H57.688c2.234-5.972,8-10.234,14.742-10.234c4.981,0,9.566,2.304,12.578,6.32c0.894,1.19,2.23,1.968,3.706,2.156
     c1.484,0.187,2.967-0.231,4.129-1.16c1.932-1.544,4.254-2.36,6.716-2.36C103.495,180.149,106.944,182.269,108.826,185.427z"/>
   <path d="M62.287,152.634c21.505,0,39-17.495,39-39c0-21.505-17.495-39-39-39s-39,17.495-39,39
     C23.287,135.139,40.782,152.634,62.287,152.634z M62.287,85.634c15.439,0,28,12.561,28,28c0,15.439-12.561,28-28,28
     s-28-12.561-28-28C34.287,98.195,46.847,85.634,62.287,85.634z"/>
   <path d="M66.858,268.174H47.359c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h19.499c3.037,0,5.5-2.462,5.5-5.5
     S69.895,268.174,66.858,268.174z"/>
   <path d="M100.982,291.385h-9.75c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h9.75c3.037,0,5.5-2.462,5.5-5.5
     S104.019,291.385,100.982,291.385z"/>
   <path d="M377.968,290.672c0-3.038-2.463-5.5-5.5-5.5h-9.75c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h9.75
     C375.505,296.172,377.968,293.71,377.968,290.672z"/>
   <path d="M306.096,324.17h-9.749c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h9.749c3.037,0,5.5-2.462,5.5-5.5
     S309.133,324.17,306.096,324.17z"/>
   <path d="M149.354,322.67h-19.499c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h19.499c3.037,0,5.5-2.462,5.5-5.5
     S152.391,322.67,149.354,322.67z"/>
   <path d="M60.859,339.169H41.36c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h19.499c3.037,0,5.5-2.462,5.5-5.5
     S63.896,339.169,60.859,339.169z"/>
   <path d="M392.342,355.669h-19.499c-3.037,0-5.5,2.462-5.5,5.5s2.463,5.5,5.5,5.5h19.499c3.037,0,5.5-2.462,5.5-5.5
     S395.379,355.669,392.342,355.669z"/>
   <path d="M314.346,249.174h-19.499c-3.037,0-5.5,2.462-5.5,5.5c0,3.038,2.463,5.5,5.5,5.5h19.499c3.037,0,5.5-2.462,5.5-5.5
     C319.846,251.636,317.383,249.174,314.346,249.174z"/>
 </g>
 </svg>
  );
}
