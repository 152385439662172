import React, { useState } from "react";
import { data, images } from "../../constants";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Menu.css";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const languages = [
  { code: "en", label: "", emoji: "🇺🇸" },
  { code: "pt-br", label: "", emoji: "🇧🇷" },
  { code: "es", label: "", emoji: "🇪🇸" },
];

const Menu = () => {
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].code);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    changeLanguageHandler(event.target.value);
  };

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <div className="container">
      <Navbar collapseOnSelect expand="lg" bg="ligth" variant="ligth">
        <Container>
          <Navbar.Brand href="#home">
            <img
              src={images.logo}
              alt="logo"
              className="logo"
              style={{ width: "150px", height: "auto" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {data.Menu.map((item, index) => (
                <Nav.Link href={item.link}>
                  <Typography variant="h6"  color="gray" >{t(item.text)}</Typography>
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
        <div className="crate-selector d-flex justify-content-end">
          <select value={selectedLanguage} onChange={handleLanguageChange}>
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.emoji} {lang.label}
              </option>
            ))}
          </select>
        </div>
      </Navbar>
    </div>
  );
};

export default Menu;
