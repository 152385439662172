export default function IconHollywood({ fillColor }) {
  return (
    <svg width="54" height="54" viewBox="0 0 64 64" id="Layer_1" version="1.1">
    <g>
  
    <g>
    <path class="st1" d="M56,16.5h-3c-0.276,0-0.5-0.224-0.5-0.5v-1c0-1.378-1.121-2.5-2.5-2.5s-2.5,1.122-2.5,2.5v1    c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-0.5c0-0.551-0.448-1-1-1s-1,0.449-1,1V16c0,0.276-0.224,0.5-0.5,0.5h-3    c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.5c0-1.103,0.897-2,2-2c0.441,0,0.851,0.144,1.182,0.387    C47.147,12.501,48.459,11.5,50,11.5c1.93,0,3.5,1.57,3.5,3.5v0.5H56c0.276,0,0.5,0.224,0.5,0.5S56.276,16.5,56,16.5z"/>
    </g>
    <g>
    <path class="st1" d="M30,18.5h-5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h5c0.276,0,0.5,0.224,0.5,0.5S30.276,18.5,30,18.5z    "/>
    </g>
    <g>
    <path class="st1" d="M11,34.5c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4    C11.5,34.276,11.276,34.5,11,34.5z"/>
    </g>
    <g>
    <path class="st1" d="M15,34.5c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4    C15.5,34.276,15.276,34.5,15,34.5z"/>
    </g>
    <g>
    <path class="st1" d="M15,32.5h-4c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4c0.276,0,0.5,0.224,0.5,0.5S15.276,32.5,15,32.5z    "/>
    </g>
    <g>
    <path class="st1" d="M18.5,33.5c-1.103,0-2-0.897-2-2v-1c0-1.103,0.897-2,2-2s2,0.897,2,2v1C20.5,32.603,19.603,33.5,18.5,33.5z     M18.5,29.5c-0.552,0-1,0.449-1,1v1c0,0.551,0.448,1,1,1s1-0.449,1-1v-1C19.5,29.949,19.052,29.5,18.5,29.5z"/>
    </g>
    <g>
    <path class="st1" d="M42.5,34.5c-1.103,0-2-0.897-2-2v-1c0-1.103,0.897-2,2-2s2,0.897,2,2v1C44.5,33.603,43.603,34.5,42.5,34.5z     M42.5,30.5c-0.552,0-1,0.449-1,1v1c0,0.551,0.448,1,1,1s1-0.449,1-1v-1C43.5,30.949,43.052,30.5,42.5,30.5z"/>
    </g>
    <g>
    <path class="st1" d="M47.5,35.5c-1.103,0-2-0.897-2-2v-1c0-1.103,0.897-2,2-2s2,0.897,2,2v1C49.5,34.603,48.603,35.5,47.5,35.5z     M47.5,31.5c-0.552,0-1,0.449-1,1v1c0,0.551,0.448,1,1,1s1-0.449,1-1v-1C48.5,31.949,48.052,31.5,47.5,31.5z"/>
    </g>
    <g>
    <path class="st1" d="M52,34.5h-1c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5h1c1.379,0,2.5,1.122,2.5,2.5    S53.379,34.5,52,34.5z M51.5,33.5H52c0.827,0,1.5-0.673,1.5-1.5s-0.673-1.5-1.5-1.5h-0.5V33.5z"/>
    </g>
    <g>
    <path class="st1" d="M24,34.5h-2c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3.5H24    c0.276,0,0.5,0.224,0.5,0.5S24.276,34.5,24,34.5z"/>
    </g>
    <g>
    <path class="st1" d="M29,35.5h-3c-0.276,0-0.5-0.224-0.5-0.5v-4c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v3.5H29    c0.276,0,0.5,0.224,0.5,0.5S29.276,35.5,29,35.5z"/>
    </g>
    <g>
    <path class="st1" d="M31,32.5c-0.128,0-0.256-0.049-0.354-0.146l-2-2c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L31,31.293l1.646-1.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-2,2C31.256,32.451,31.128,32.5,31,32.5z"/>
    </g>
    <g>
    <path class="st1" d="M31,34.5c-0.276,0-0.5-0.224-0.5-0.5v-2c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v2    C31.5,34.276,31.276,34.5,31,34.5z"/>
    </g>
    <g>
    <path class="st1" d="M38,34.5c-0.188,0-0.362-0.106-0.447-0.276L37,33.118l-0.553,1.105c-0.093,0.186-0.288,0.298-0.502,0.273    c-0.208-0.023-0.38-0.173-0.431-0.376l-1-4c-0.066-0.268,0.096-0.539,0.364-0.606c0.262-0.071,0.539,0.095,0.606,0.364    l0.672,2.688l0.396-0.791c0.17-0.339,0.725-0.339,0.895,0l0.396,0.791l0.672-2.688c0.066-0.268,0.335-0.434,0.606-0.364    c0.269,0.067,0.431,0.338,0.364,0.606l-1,4c-0.051,0.203-0.223,0.353-0.431,0.376C38.036,34.499,38.018,34.5,38,34.5z"/>
    </g>
    <g>
    <path class="st1" d="M50,42.5c-0.053,0-0.105-0.008-0.158-0.026l-6-2c-2.892-0.964-7.89,0.061-10.065-1.027    c-0.297-0.148-0.571-0.296-0.841-0.442C31.438,38.198,30.145,37.5,26,37.5c-2.57,0-5.771,0.847-8.779,2.325    c-0.249,0.121-0.548,0.02-0.67-0.229c-0.121-0.248-0.019-0.547,0.229-0.669C19.922,37.385,23.282,36.5,26,36.5    c4.396,0,5.86,0.79,7.41,1.625c0.261,0.14,0.526,0.284,0.813,0.427C35.118,39,36.821,39,38.625,39c1.945,0,3.956,0,5.533,0.526    l6,2c0.262,0.087,0.403,0.37,0.316,0.632C50.404,42.368,50.209,42.5,50,42.5z"/>
    </g>
    <g>
    <path class="st1" d="M45.999,39.414c-0.235,0-0.445-0.167-0.49-0.408c-0.051-0.271,0.128-0.533,0.399-0.583    c0.775-0.146,1.28-0.482,1.814-0.838C48.484,37.076,49.349,36.5,51,36.5c1.554,0,2.224-0.274,2.872-0.539    C54.428,35.735,55.003,35.5,56,35.5c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5c-0.801,0-1.22,0.171-1.75,0.387    C53.547,37.174,52.75,37.5,51,37.5c-1.349,0-1.985,0.425-2.723,0.916c-0.595,0.396-1.208,0.806-2.186,0.989    C46.062,39.411,46.03,39.414,45.999,39.414z"/>
    </g>
    <g>
    <path class="st1" d="M28,53.5c-0.096,0-0.192-0.027-0.277-0.084c-0.229-0.153-0.292-0.463-0.139-0.693    c0.086-0.129,2.155-3.173,6.295-4.208c2.157-0.54,2.477-0.762,2.846-1.019c0.344-0.239,0.733-0.51,2.117-0.971    c0.595-0.198,1.03-0.357,1.379-0.484C41.712,45.5,41.773,45.5,45,45.5c2.123,0,2.653-0.58,3.121-1.092    c0.39-0.425,0.83-0.908,1.879-0.908c0.712,0,1.325-0.533,2.035-1.151C52.982,41.525,54.161,40.5,56,40.5    c0.276,0,0.5,0.224,0.5,0.5s-0.224,0.5-0.5,0.5c-1.465,0-2.402,0.815-3.309,1.604C51.902,43.79,51.086,44.5,50,44.5    c-0.608,0-0.797,0.207-1.141,0.583C48.313,45.681,47.563,46.5,45,46.5c-3.112,0-3.112,0-4.438,0.482    c-0.354,0.129-0.799,0.291-1.404,0.492c-1.247,0.416-1.56,0.633-1.862,0.844c-0.45,0.313-0.84,0.583-3.175,1.167    c-3.769,0.942-5.687,3.764-5.705,3.792C28.319,53.422,28.161,53.5,28,53.5z"/>
    </g>
    <g>
    <path class="st1" d="M27.999,50.5c-0.095,0-0.191-0.027-0.276-0.084C27.694,50.397,24.824,48.5,23,48.5    c-1.367,0-1.983-0.79-2.58-1.553c-0.427-0.546-0.867-1.112-1.644-1.5c-1.271-0.636-1.501-0.943-1.694-1.449    c-0.066-0.174-0.129-0.338-0.436-0.645l-3-3C11.927,38.634,10.421,38.5,10,38.5H8c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2    c0.664,0,2.416,0.209,4.354,2.146l3,3c0.457,0.458,0.576,0.768,0.663,0.995c0.084,0.221,0.145,0.381,1.207,0.912    c0.978,0.489,1.513,1.174,1.985,1.779C21.758,47.035,22.121,47.5,23,47.5c2.127,0,5.149,1.999,5.277,2.084    c0.229,0.153,0.292,0.463,0.139,0.693C28.319,50.422,28.161,50.5,27.999,50.5z"/>
    </g>
    <g>
    <path class="st1" d="M26,43.5c-0.128,0-0.256-0.049-0.354-0.146l-1-1c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L26,42.293l0.646-0.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-1,1C26.256,43.451,26.128,43.5,26,43.5z"/>
    </g>
    <g>
    <path class="st1" d="M12,47.5c-0.128,0-0.256-0.049-0.354-0.146l-1-1c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L12,46.293l0.646-0.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-1,1C12.256,47.451,12.128,47.5,12,47.5z"/>
    </g>
    <g>
    <path class="st1" d="M17,50.5c-0.128,0-0.256-0.049-0.354-0.146l-1-1c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L17,49.293l0.646-0.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-1,1C17.256,50.451,17.128,50.5,17,50.5z"/>
    </g>
    <g>
    <path class="st1" d="M35,45.5c-0.128,0-0.256-0.049-0.354-0.146l-1-1c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L35,44.293l0.646-0.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-1,1C35.256,45.451,35.128,45.5,35,45.5z"/>
    </g>
    <g>
    <path class="st1" d="M42,51.5c-0.128,0-0.256-0.049-0.354-0.146l-1-1c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L42,50.293l0.646-0.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-1,1C42.256,51.451,42.128,51.5,42,51.5z"/>
    </g>
    <g>
    <path class="st1" d="M52,49.5c-0.128,0-0.256-0.049-0.354-0.146l-1-1c-0.195-0.195-0.195-0.512,0-0.707s0.512-0.195,0.707,0    L52,48.293l0.646-0.646c0.195-0.195,0.512-0.195,0.707,0s0.195,0.512,0,0.707l-1,1C52.256,49.451,52.128,49.5,52,49.5z"/>
    </g>
    <g>
    <path class="st1" d="M18,27.884c-0.217,0-0.417-0.142-0.48-0.36c-0.077-0.265,0.075-0.542,0.341-0.62    C20.062,26.263,23.086,25.5,25,25.5c0.773,0,2.234,0.064,4.083,0.145c5.089,0.222,13.609,0.597,15.759-0.119    c3.013-1.004,10.768-1.981,11.096-2.022c0.27-0.03,0.524,0.159,0.559,0.434c0.034,0.274-0.16,0.524-0.434,0.558    c-0.08,0.01-7.985,1.005-10.904,1.978c-2.326,0.776-10.627,0.411-16.119,0.17C27.202,26.563,25.752,26.5,25,26.5    c-1.401,0-3.838,0.484-6.86,1.364C18.093,27.877,18.046,27.884,18,27.884z"/>
    </g>
    <g>
    <path class="st1" d="M24,24.5c-0.053,0-0.105-0.008-0.158-0.025C23.813,24.465,20.88,23.5,18,23.5    c-1.393,0-2.586-0.407-3.851-0.837C12.549,22.118,10.734,21.5,8,21.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5    c2.9,0,4.798,0.646,6.472,1.216C15.707,22.137,16.773,22.5,18,22.5c3.046,0,6.032,0.984,6.158,1.026    c0.262,0.087,0.403,0.37,0.316,0.632C24.404,24.368,24.209,24.5,24,24.5z"/>
    </g>
    <g>
    <path class="st1" d="M45,26.5c-0.143,0-0.281-0.061-0.378-0.172c-0.132-0.153-0.16-0.371-0.069-0.551    c0.043-0.086,1.095-2.142,4.17-4.192C51.813,19.524,55.83,19.5,56,19.5c0.276,0,0.5,0.224,0.5,0.5c0,0.276-0.224,0.5-0.5,0.5    c-0.038,0-3.889,0.027-6.723,1.916c-1.5,1-2.482,2.003-3.077,2.729c3.511-0.857,9.451-1.605,9.737-1.641    c0.27-0.03,0.524,0.159,0.559,0.434c0.034,0.274-0.16,0.524-0.434,0.558c-0.08,0.01-7.985,1.005-10.904,1.978    C45.106,26.492,45.053,26.5,45,26.5z"/>
    </g>
    <g>
    <path class="st1" d="M21,11.5h-7c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h7c0.276,0,0.5,0.224,0.5,0.5S21.276,11.5,21,11.5z    "/>
    </g>
    </g>
    </svg>
  );
}
