export default function IconRoute66({ fillColor }) {
  return (
    <svg height="54" width="54" version="1.1" id="_x32_"
	 viewBox="0 0 512 512">
<g>
	<path class="st0" d="M500.79,327.211c-1.468-17.291-5.244-32.057-9.985-45.001c-3.552-9.707-7.623-18.403-11.615-26.473
		c-6-12.117-11.746-22.852-15.832-33.726c-4.093-10.904-6.688-21.886-6.711-35.447c0-2.378,0.084-4.826,0.254-7.375
		c0.456-6.634,1.877-13.661,3.985-20.681c3.159-10.541,7.862-21.068,12.804-30.358c4.936-9.306,10.11-17.384,14.102-23.16
		l6.441-9.313L404.032,4.603l-9.074,5.136c-18.195,10.279-39.1,16.132-61.457,16.132c-14.048,0-26.412-1.506-37.718-4.742
		c-11.322-3.236-21.662-8.186-31.857-15.461L255.996,0l-7.931,5.668c-10.194,7.275-20.535,12.225-31.849,15.461
		c-11.313,3.236-23.67,4.742-37.717,4.742c-22.357,0-43.263-5.854-61.465-16.132l-9.066-5.136l-90.21,91.074l6.441,9.313
		c5.328,7.707,12.734,19.508,18.928,32.814c3.09,6.649,5.877,13.677,7.978,20.705c2.116,7.02,3.537,14.047,3.985,20.681
		c0.17,2.549,0.263,4.996,0.263,7.375c-0.015,12.055-2.093,22.056-5.413,31.818c-2.487,7.282-5.738,14.426-9.414,21.885
		c-5.475,11.183-11.947,23.092-17.515,37.17c-5.568,14.064-10.164,30.312-11.808,49.773c-0.339,3.938-0.501,7.869-0.501,11.77
		c0,34.79,13.19,67.032,35.107,91.29c21.901,24.272,52.7,40.676,87.7,43.742c30.844,2.679,53.472,5.136,70.978,9.553
		c8.765,2.193,16.264,4.849,23.145,8.232c6.881,3.382,13.198,7.506,19.616,12.874l8.75,7.329l8.757-7.329
		c6.418-5.367,12.727-9.491,19.616-12.874c5.159-2.541,10.665-4.657,16.79-6.487c9.19-2.758,19.832-4.842,32.535-6.603
		c12.696-1.753,27.454-3.182,44.8-4.695c34.991-3.066,65.789-19.47,87.698-43.742c21.917-24.257,35.107-56.499,35.107-91.29
		C501.3,335.08,501.13,331.149,500.79,327.211z M52.881,98.967l60.075-60.631c19.878,9.483,42.128,14.812,65.543,14.812
		c15.986,0,30.984-1.722,45.216-5.792c11.282-3.22,22.033-7.962,32.281-14.264c10.248,6.302,20.998,11.044,32.28,14.264
		c14.241,4.07,29.238,5.792,45.225,5.792c23.415,0,45.664-5.328,65.535-14.812l60.083,60.631
		c-4.811,7.638-10.133,16.936-14.967,27.338c-3.552,7.615-6.819,15.808-9.383,24.366c-1.552,5.174-2.834,10.48-3.761,15.878H80.984
		c-0.92-5.398-2.202-10.703-3.754-15.878c-3.86-12.836-9.282-24.852-14.842-35.324C59.183,109.307,55.924,103.809,52.881,98.967z
		 M445.951,411.991c-17.554,19.415-41.934,32.397-69.836,34.852c-31.076,2.734-54.747,5.151-75.243,10.264
		c-10.232,2.564-19.654,5.846-28.528,10.209c-5.676,2.788-11.105,6.008-16.349,9.707c-5.244-3.7-10.665-6.92-16.341-9.707
		c-6.657-3.267-13.63-5.931-21.006-8.14c-11.082-3.306-23.068-5.607-36.598-7.476c-13.553-1.87-28.69-3.322-46.166-4.858
		c-27.902-2.455-52.282-15.437-69.836-34.852c-17.539-19.415-28.08-45.155-28.08-73.01c0-3.121,0.139-6.287,0.402-9.46
		c1.266-14.704,4.379-26.837,8.433-37.942c3.05-8.317,6.649-16.056,10.457-23.77c5.692-11.546,11.931-23.045,16.889-36.174
		c4.958-13.089,8.495-27.871,8.472-45.07c0-0.177-0.016-0.363-0.016-0.533h346.78c0,0.17-0.016,0.356-0.016,0.533
		c-0.007,15.283,2.772,28.675,6.882,40.645c3.081,8.981,6.865,17.19,10.742,25.076c5.815,11.838,11.808,22.967,16.627,35.184
		c4.827,12.217,8.572,25.516,10.008,42.05c0.262,3.173,0.402,6.34,0.402,9.46C474.031,366.836,463.49,392.576,445.951,411.991z"/>
	<path class="st0" d="M145.77,116.412h9.074c3.714,0,6.302,0.757,7.761,2.294c1.468,1.537,2.255,4.078,2.386,7.615
		c0.263,7.252,0.425,11.236,0.494,11.947c0.316,2.718,0.942,4.572,1.854,5.576h12.588c-2.085-1.243-3.189-3.066-3.32-5.491
		c-0.194-5.544-0.325-9.822-0.394-12.827c-0.325-7.963-3.962-12.272-10.928-12.928v-0.178c3.707-0.532,6.611-2.232,8.688-5.136
		c1.946-2.71,2.928-6.17,2.928-10.348c0-5.606-1.73-9.792-5.167-12.572c-3.066-2.472-7.228-3.714-12.495-3.714h-24.589v63.194h11.12
		V116.412z M145.77,88.263h9.468c7.02,0,10.541,3.421,10.541,10.263c0,3.189-0.972,5.691-2.927,7.53
		c-1.954,1.822-4.688,2.734-8.201,2.734h-8.882V88.263z"/>
	<path class="st0" d="M208.424,145.079c7.992,0,13.839-2.634,17.514-7.915c3.669-5.283,5.506-13.584,5.506-24.922
		c0-11.322-1.838-19.631-5.506-24.914c-3.676-5.274-9.522-7.916-17.514-7.916c-8.009,0-13.847,2.642-17.523,7.916
		c-3.676,5.283-5.506,13.592-5.506,24.914c0,11.337,1.83,19.639,5.506,24.922C194.577,142.445,200.416,145.079,208.424,145.079z
		 M200.037,91.529c1.738-3.003,4.542-4.51,8.388-4.51c3.838,0,6.665,1.507,8.487,4.51c2.278,3.839,3.414,10.658,3.414,20.45
		c0,10.031-1.136,16.99-3.414,20.89c-1.822,3.066-4.649,4.595-8.487,4.595c-3.846,0-6.649-1.529-8.388-4.595
		c-2.347-3.9-3.528-10.858-3.528-20.89C196.508,102.187,197.69,95.368,200.037,91.529z"/>
	<path class="st0" d="M261.804,145.079c14.186,0,21.276-6.626,21.276-19.917V80.649h-11.136v45.324
		c0,7.661-3.374,11.491-10.14,11.491c-6.835,0-10.248-3.83-10.248-11.491V80.649h-11.128v44.513
		C240.427,138.452,247.555,145.079,261.804,145.079z"/>
	<polygon class="st0" points="305.128,143.843 316.249,143.843 316.249,89.144 332.551,89.144 332.551,80.649 288.532,80.649 
		288.532,89.144 305.128,89.144 	"/>
	<polygon class="st0" points="377.342,135.348 350.02,135.348 350.02,115.169 375.002,115.169 375.002,106.666 350.02,106.666 
		350.02,89.144 376.563,89.144 376.563,80.649 338.899,80.649 338.899,143.843 377.342,143.843 	"/>
	<path class="st0" d="M222.387,298.738c-8.742-5.753-19.206-8.788-30.242-8.781h-0.023l26.905-49.935l0.07-0.123
		c0.316-0.634,0.611-1.344,0.85-2.171c0.224-0.841,0.402-1.799,0.402-2.919c0-1.298-0.262-2.857-0.98-4.356
		c-0.533-1.112-1.313-2.154-2.194-2.958c-1.32-1.235-2.795-1.938-4.062-2.309c-1.275-0.371-2.378-0.478-3.367-0.478H193.79
		c-0.626,0-1.359,0.015-2.332,0.154c-0.726,0.1-1.599,0.286-2.549,0.656c-1.444,0.541-2.98,1.56-4,2.687
		c-0.958,1.02-1.545,1.977-1.977,2.819l-0.008-0.008l-40.598,75.327c-3.259,6.048-6.147,12.48-8.232,19.268
		c-2.093,6.788-3.39,13.948-3.39,21.33c0,8.634,1.49,16.642,4.332,23.778c4.248,10.719,11.63,19.392,20.944,25.168
		c9.336,5.8,20.496,8.734,32.412,8.734c7.892,0,15.492-1.297,22.45-3.907c10.448-3.884,19.484-10.781,25.755-20.164
		c6.286-9.368,9.731-21.106,9.715-34.312c0-8.302-1.444-16.032-4.155-22.937C238.094,312.94,231.137,304.476,222.387,298.738z
		 M206.857,356.372c-1.46,3.923-3.73,6.819-6.712,8.881c-2.996,2.032-6.819,3.306-11.754,3.32c-3.282,0-6.062-0.579-8.41-1.551
		c-3.521-1.476-6.155-3.769-8.148-7.02c-1.953-3.244-3.189-7.53-3.197-12.836c0-3.514,0.549-6.588,1.491-9.174
		c1.437-3.885,3.646-6.718,6.572-8.727c2.942-1.992,6.726-3.243,11.692-3.259c3.298,0,6.124,0.572,8.511,1.544
		c3.575,1.468,6.263,3.738,8.247,6.943c1.97,3.205,3.228,7.406,3.236,12.673C208.386,350.673,207.822,353.762,206.857,356.372z"/>
	<polygon class="st0" points="142.326,306.344 142.326,306.344 142.334,306.329 	"/>
	<path class="st0" d="M357.372,298.738c-8.75-5.753-19.214-8.788-30.25-8.781h-0.024l26.913-49.935l0.07-0.123
		c0.309-0.634,0.603-1.344,0.834-2.171c0.232-0.841,0.41-1.799,0.41-2.919c0-1.298-0.262-2.857-0.981-4.356
		c-0.525-1.112-1.313-2.154-2.185-2.958c-1.336-1.235-2.811-1.938-4.062-2.309c-1.274-0.371-2.386-0.478-3.367-0.478h-15.962
		c-0.618,0-1.351,0.015-2.325,0.154c-0.726,0.1-1.598,0.286-2.556,0.656c-1.437,0.541-2.973,1.56-4.001,2.687
		c-0.958,1.02-1.536,1.977-1.977,2.819v-0.008l-40.621,75.358c-3.251,6.031-6.132,12.464-8.224,19.237
		c-2.086,6.788-3.375,13.948-3.375,21.33c-0.008,8.634,1.475,16.642,4.325,23.778c4.256,10.719,11.63,19.392,20.952,25.168
		c9.321,5.8,20.488,8.734,32.412,8.734c7.893,0,15.477-1.297,22.45-3.907c10.441-3.884,19.485-10.781,25.755-20.164
		c6.279-9.368,9.723-21.114,9.708-34.312c0.007-8.302-1.437-16.032-4.163-22.937C373.064,312.94,366.106,304.476,357.372,298.738z
		 M341.842,356.372c-1.46,3.923-3.738,6.819-6.718,8.881c-3.004,2.032-6.819,3.306-11.747,3.32c-3.298,0-6.07-0.579-8.41-1.551
		c-3.521-1.476-6.162-3.769-8.147-7.02c-1.962-3.244-3.205-7.53-3.205-12.836c0-3.514,0.556-6.588,1.499-9.174
		c1.428-3.885,3.645-6.718,6.572-8.727c2.935-1.992,6.719-3.243,11.692-3.259c3.298,0,6.117,0.572,8.503,1.544
		c3.576,1.468,6.255,3.738,8.256,6.943c1.969,3.205,3.22,7.406,3.22,12.673C343.355,350.673,342.799,353.762,341.842,356.372z"/>
	<polygon class="st0" points="269.526,306.329 269.472,306.429 269.503,306.375 	"/>
</g>
</svg>
  );
}
