import React from "react";
import Typography from "@mui/material/Typography";
import "./AboutUs.css";
import Lottie from "react-lottie";

import { Headings } from "../../components";
import { Fade } from "react-reveal";

import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import IconFounded from "../../components/Icon/IconFounded";
import IconExperience from "../../components/Icon/IconExperience";
import IconAgreement from "../../components/Icon/IconAgreement";
import square_1 from "../../assets/json/square_1.json";
import square_2 from "../../assets/json/square_2.json";
import square_3 from "../../assets/json/square_3.json";

const AboutUs = () => {
  const { t } = useTranslation();
  const texts = ["about_us_text_1", "about_us_text_2", "about_us_text_3"];

  const firstSquare = {
    loop: true,
    autoplay: true,
    animationData: square_1,
  };
  const secondSquare = {
    loop: true,
    autoplay: true,
    animationData: square_2,
  };
  const thirdSquare = {
    loop: true,
    autoplay: true,
    animationData: square_3,
  };

  return (
    <div className="section-padding" id="about-us">
      <div
        className="aboutus-background"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="overlay-aboutus" />
        <div className="container">
          <Headings title={t("menu_about_us")} text="" />
          <Grid
            sx={{ flexGrow: 1, marginBottom: 20, marginTop: 0}}
            container
            spacing={4}
          >
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={5}>
                {[0, 1, 2].map((index, value) => (
                  <Grid key={value} item>
                    <Fade bottom duration={1000 * index} distance="40px">
                      <Paper
                        sx={{
                          height: 400,
                          width: 350,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          backgroundColor: (theme) =>
                            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              height: 80,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {index === 0 ? (
                              <Lottie
                                options={firstSquare}
                                speed={0.8}
                                height={100}
                                width={100}
                              />
                            ) : index === 1 ? (
                              <Lottie
                                options={secondSquare}
                                speed={0.5}
                                height={100}
                                width={100}
                              />
                            ) : (
                              <Lottie
                                options={thirdSquare}
                                speed={0.6}
                                height={100}
                                width={100}
                              />
                            )}
                          </Box>
                          <Box
                            sx={{
                              height: 200,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography align="center" className="m-4">
                              {t(texts[value])}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Fade>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
