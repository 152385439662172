export default function IconCircus({ fillColor }) {
  return (
<svg fill="#000000" height="54" width="54" version="1.1" id="Layer_1"
	 viewBox="0 0 512 512">
<g>
	<g>
		<path d="M470.151,178.249c-0.066-0.101-0.134-0.198-0.203-0.297l-25.199-35.711c-2.946-4.173-7.734-6.655-12.841-6.655
			c-0.021,0-0.042,0-0.064,0c-5.13,0.021-9.927,2.544-12.85,6.759l-4.727,6.815c-1.839-3.352-3.783-6.637-5.824-9.856v-26.691
			c0-8.679-7.036-15.715-15.715-15.715c-5.597,0-10.497,2.935-13.282,7.34c-20.327-19.107-45.072-33.56-72.519-41.65
			c-1.34-3.056-2.952-6.016-4.848-8.838c-0.067-0.1-0.135-0.198-0.204-0.297L268.85,6.655C265.905,2.481,261.117,0,256.011,0
			c-0.021,0-0.042,0-0.064,0c-5.13,0.021-9.927,2.544-12.85,6.759l-32.074,46.24c-2.183,3.006-4.034,6.184-5.557,9.48
			c-27.596,8.068-52.472,22.567-72.891,41.76c-2.784-4.406-7.685-7.34-13.282-7.34c-8.679,0-15.715,7.036-15.715,15.715v26.691
			c-2.028,3.198-3.959,6.461-5.787,9.791l-4.837-6.854c-2.946-4.173-7.734-6.655-12.841-6.655c-0.021,0-0.042,0-0.064,0
			c-5.13,0.021-9.926,2.544-12.85,6.758l-24.452,35.252c-10.072,13.886-11.709,32.135-4.26,47.683
			c7.247,15.129,21.324,24.727,37.825,25.92c7.472,87.019,76.984,156.576,163.982,164.122v19.169h-57.103
			c-21.952,0-39.813,17.86-39.813,39.813v21.983c0,8.679,7.036,15.715,15.715,15.715h193.83c8.679,0,15.715-7.036,15.715-15.715
			v-21.983c0-21.952-17.86-39.813-39.813-39.813h-57.103v-19.169c87.009-7.547,156.528-77.121,163.986-164.155
			c16.091-1.309,29.971-10.648,37.355-25.289C480.757,210.623,479.642,192.373,470.151,178.249z M93.205,211.724
			c-1.183,2.344-4.759,7.833-12.418,8.177c-0.59,0.026-1.195,0.025-1.772,0.001c-7.615-0.344-11.056-5.85-12.183-8.201
			c-2.502-5.225-1.978-11.096,1.402-15.709c0.08-0.109,0.158-0.22,0.236-0.33l11.753-16.945L92.349,195.9
			C95.484,200.661,95.807,206.565,93.205,211.724z M236.508,71.396c0.08-0.109,0.158-0.22,0.236-0.331l19.377-27.936l19.956,28.281
			c5.124,7.727,5.668,17.315,1.449,25.684c-0.926,1.836-2.765,4.847-5.8,7.554V97.11c0-8.679-7.036-15.715-15.715-15.715
			c-8.679,0-15.715,7.036-15.715,15.715v7.901c-3.256-2.833-5.156-6.089-6.088-8.035C230.138,88.479,230.998,78.917,236.508,71.396z
			 M328.829,465.92c4.621,0,8.382,3.76,8.382,8.382v6.267H174.811v-6.267c0-4.621,3.76-8.382,8.382-8.382H328.829z M277.525,354.498
			c-0.897,1.778-2.653,4.659-5.52,7.299v-7.894c0-8.679-7.036-15.715-15.715-15.715c-8.679,0-15.715,7.036-15.715,15.715v8.736
			c-3.422-2.884-5.409-6.263-6.367-8.262c-4.069-8.495-3.209-18.057,2.301-25.577c0.592-0.81,1.107-1.675,1.536-2.583l18.107-38.384
			l18.72,38.899c0.326,0.676,0.699,1.327,1.116,1.949C281.195,336.43,281.769,346.081,277.525,354.498z M354.494,347.274v-6.99
			c0-8.679-7.036-15.715-15.715-15.715s-15.715,7.036-15.715,15.715v27.24c0,0.358,0.03,0.708,0.053,1.06
			c-7.479,3.791-15.319,6.969-23.455,9.468c2.237-2.886,4.226-6.026,5.929-9.4c9.104-18.054,7.992-39.58-2.845-56.481
			l-32.574-67.685c-2.619-5.442-8.125-8.9-14.162-8.9c-0.021,0-0.041,0-0.061,0c-6.06,0.023-11.568,3.53-14.153,9.01l-31.588,66.958
			c-11.443,16.602-13.135,38.06-4.347,56.404c1.72,3.59,3.762,6.921,6.08,9.97c-7.987-2.479-15.684-5.617-23.037-9.342
			c0.023-0.352,0.053-0.702,0.053-1.06v-27.24c0-8.679-7.036-15.715-15.715-15.715c-8.679,0-15.715,7.036-15.715,15.715v6.99
			c-29.309-25.886-48.385-63.1-50.297-104.721c5.789-4.243,10.615-9.886,14.039-16.675c7.657-15.18,6.581-33.326-2.784-47.422
			c15.467-37.06,45.488-66.585,82.887-81.384c0.915,4.598,2.402,9.129,4.488,13.483c8.957,18.696,26.556,30.408,47.065,31.332
			c0.93,0.043,1.865,0.064,2.806,0.064c0.939,0,1.874-0.021,2.79-0.064c20.257-0.911,37.853-12.366,47.069-30.638
			c2.301-4.562,3.947-9.346,4.95-14.22c37.583,14.838,67.724,44.543,83.157,81.824c-9.29,13.72-10.642,31.341-3.415,46.428
			c3.444,7.19,8.443,13.116,14.499,17.505C402.809,284.312,383.748,321.435,354.494,347.274z M445.001,211.724
			c-1.182,2.344-4.758,7.833-12.417,8.177c-0.59,0.026-1.195,0.025-1.772,0.001c-7.615-0.344-11.057-5.85-12.184-8.201
			c-2.502-5.223-1.979-11.096,1.402-15.708c0.08-0.109,0.158-0.22,0.236-0.331l11.753-16.944l12.127,17.185
			C447.28,200.662,447.603,206.565,445.001,211.724z"/>
	</g>
</g>
</svg>  );
}
