import * as React from "react";
import Card from "@mui/material/Card";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./ServiceModal1.css";
import { Fade } from "react-reveal";
import TitleModal from "./TitleModal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardLine from "./CardLine";

const ServiceModal1 = ({ showModalCitie, setOpen }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 600px)");

  let data = null;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: false,
    cssEase: "linear",
    adaptiveHeight: true,
    height: "inherit",
    arrows: true,
    margin: 5,
  };

  if (showModalCitie === "LV") {
    data = [
      { name: t("service_1_subtitle_1_description_1"), icon: "icon_transfer" },
      { name: t("service_1_subtitle_1_description_2"), icon: "icon_shopping" },
      { name: t("service_1_subtitle_1_description_3"), icon: "icon_tour" },
      { name: t("service_1_subtitle_1_description_4"), icon: "icon_canyon" },
      { name: t("service_1_subtitle_1_description_5"), icon: "icon_canyon" },
      { name: t("service_1_subtitle_1_description_6"), icon: "icon_bridge" },
      {
        name: t("service_1_subtitle_1_description_7"),
        icon: "icon_helicopter",
      },
      { name: t("service_1_subtitle_1_description_8"), icon: "icon_route66" },
      { name: t("service_1_subtitle_1_description_9"), icon: "icon_mount" },
      { name: t("service_1_subtitle_1_description_10"), icon: "icon_circus" },
      { name: t("service_1_subtitle_1_description_11"), icon: "icon_sphere" },
      { name: t("service_1_subtitle_1_description_12"), icon: "icon_other" },
    ];
  } else if (showModalCitie === "LA") {
    data = [
      { name: t("service_1_subtitle_2_description_1"), icon: "icon_transfer" },
      { name: t("service_1_subtitle_2_description_2"), icon: "icon_shopping" },
      { name: t("service_1_subtitle_2_description_3"), icon: "icon_hollywood" },
      { name: t("service_1_subtitle_2_description_4"), icon: "icon_tour" },
      { name: t("service_1_subtitle_2_description_5"), icon: "icon_beach" },
      { name: t("service_1_subtitle_2_description_6"), icon: "icon_disney" },
      { name: t("service_1_subtitle_2_description_7"), icon: "icon_other" },
    ];
  } else if (showModalCitie === "SF") {
    data = [
      { name: t("service_1_subtitle_3_description_1"), icon: "icon_transfer" },
      { name: t("service_1_subtitle_3_description_2"), icon: "icon_shopping" },
      { name: t("service_1_subtitle_3_description_3"), icon: "icon_tour" },
      { name: t("service_1_subtitle_3_description_4"), icon: "icon_tour" },
      { name: t("service_1_subtitle_3_description_5"), icon: "icon_prison" },
      { name: t("service_1_subtitle_3_description_6"), icon: "icon_valley" },
      { name: t("service_1_subtitle_3_description_7"), icon: "icon_other" },
    ];
  }

  const handleClose = () => {
    setOpen(false);
  };

  let renderedComponentTitle = null;

  if (showModalCitie === "LV") {
    renderedComponentTitle = (
      <TitleModal
        title={t("service_1_subtitle_1")}
        subtitle={t("service_1_subtitle_1_description")}
        handleClose={handleClose}
      />
    );
  } else if (showModalCitie === "LA") {
    renderedComponentTitle = (
      <TitleModal
        title={t("service_1_subtitle_2")}
        subtitle={t("service_1_subtitle_2_description")}
        handleClose={handleClose}
      />
    );
  } else if (showModalCitie === "SF") {
    renderedComponentTitle = (
      <TitleModal
        title={t("service_1_subtitle_3")}
        subtitle={t("service_1_subtitle_3_description")}
        handleClose={handleClose}
      />
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column"}}>
      <Box
        sx={{ display: "flex", flexDirection: "column", bgcolor: "#f6911e55" }}
      >
        <Box sx={{ margin: 2 }}>{renderedComponentTitle}</Box>
      </Box>
      <Slider {...settings} adaptiveHeight>
        {data.map((service, index) => (
          <div key={index}>
            <Fade bottom duration={2000} distance="40px">
              {!isMobile ? (
                <Box sx={{ marginLeft: 6, marginTop: 6  }}>
                  <CardLine data={[service]} />
                </Box>
              ) : (
                <CardLine data={[service]} />
              )}
            </Fade>
          </div>
        ))}
      </Slider>
    </Box>
  );
};
export default ServiceModal1;
