import React from "react";
import "./Footer.css";
import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TitleModal from "../../components/ServiceCard/TitleModal";
import { Fade } from "react-reveal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Footer = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <footer>
      <div className="row align-items-center">
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6" gutterBottom align="center">
                  Las Vegas Office
                </Typography>
                <Typography variant="h7" gutterBottom align="center">
                  6467 Haypress ct, Las Vegas
                </Typography>
                <Typography variant="h7" gutterBottom align="center">
                  NV 89141, USA
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h7"
                  gutterBottom
                  align="center"
                ></Typography>
                <Typography
                  variant="h7"
                  gutterBottom
                  align="center"
                ></Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h7" gutterBottom align="center">
                  {}
                </Typography>

                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  onClick={handleClickOpen}
                >
                  {t("privacy_policy")}
                </Typography>

                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      bgcolor: "#f6911e55",
                    }}
                  >
                    <Box sx={{ margin: 2 }}>
                      {
                        <TitleModal
                          title={t("privacy_policy")}
                          subtitle={""}
                          handleClose={handleClose}
                        />
                      }
                    </Box>
                  </Box>
                  <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
                    <Fade bottom duration={1000} distance="40px">
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          {t("privacy_policy_description")}
                        </DialogContentText>
                      </DialogContent>

                      <DialogTitle>
                        {t("privacy_policy_information_collection")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {t(
                            "privacy_policy_information_collection_description"
                          )}
                        </DialogContentText>
                      </DialogContent>

                      <DialogTitle>
                        {t("privacy_policy_use_information")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {t("privacy_policy_use_information_description")}
                        </DialogContentText>
                      </DialogContent>

                      <DialogTitle>
                        {t("privacy_policy_information_sharing")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {t("privacy_policy_information_sharing_description")}
                        </DialogContentText>
                      </DialogContent>

                      <DialogTitle>{t("privacy_policy_security")}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {t("privacy_policy_security_description")}
                        </DialogContentText>
                      </DialogContent>

                      <DialogTitle>
                        {t("privacy_policy_changes_to_this_policy")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {t(
                            "privacy_policy_changes_to_this_policy_description"
                          )}
                        </DialogContentText>
                      </DialogContent>

                      <DialogTitle>
                        {t("privacy_policy_contact_us")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          {t("privacy_policy_doubt")}
                        </DialogContentText>
                      </DialogContent>

                      <DialogContent>
                        <DialogContentText>
                          {t("privacy_policy_last_update")}
                        </DialogContentText>
                      </DialogContent>
                    </Fade>
                  </div>

                  <DialogActions>
                    
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </footer>
  );
};

export default Footer;
/*<Grid container>
                      <Box
                        mt={2}
                        mb={5}
                        display="flex"
                        justifyContent="flex-end"
                        width="100%"
                      >
                        <ColorButton type="submit" variant="contained">
                          {t("contact_us_button_submit")}
                        </ColorButton>
                      </Box>
                    </Grid>*/