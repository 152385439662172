import 'bootstrap/dist/css/bootstrap.min.css';
import { AboutUs, Contact, Footer, Hero, Services} from './container';
import { Menu } from './components';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { images } from "./constants";


const steps = [
  {
    id: '0',
    message: 'Hey I am Glauco, your virtual assistant.',
    trigger: '1',
  },
  {
    id: '1',
    message: `What subject are you interested in?`,
    trigger: 'subject',
  },
  {
    id: 'subject',
    options: [
      { value: 'subject1', label: 'Tour Operator and Leisure Groups', trigger: 'subject1Selected' },
      { value: 'subject2', label: 'Corporate Events and Incentive Trips', trigger: 'subject2Selected' },
      { value: 'subject3', label: 'Congress & Trade Show Production', trigger: 'subject3Selected' },
    ],
  },
  {
    id: 'subject1Selected',
    options: [
      { value: 'subject1Item', label: 'Los Angeles', trigger: 'end' },
      { value: 'subject2Item', label: 'Las Vegas', trigger: 'end' },
      { value: 'subject3Item', label: 'San Francisco', trigger: 'end' },
      { value: 'subject4Item', label: 'Back Menu', trigger: '1' },
    ],
  },
  {
    id: 'subject2Selected',
    options: [
      { value: 'subject5Item', label: 'Corporate Events', trigger: 'end' },
      { value: 'subject6Item', label: 'Incentive Travel', trigger: 'end' },
      { value: 'subject7Item', label: 'Back Menu', trigger: '1' },
    ],
  },
  {
    id: 'subject3Selected',
    message: 'You selected Assunto 3 as your subject. How can I assist you further?',
    trigger: '2',
  },
  {
    id: 'subject1ItemSelected',
    options: [
      { value: 'subject1Item', label: 'Los Angeles', trigger: 'end' },
      { value: 'subject2Item', label: 'Las Vegas', trigger: 'end' },
      { value: 'subject3Item', label: 'San Francisco', trigger: 'end' },
    ],
  },
  {
    id: 'subject2ItemSelected',
    options: [
      { value: 'subject1Item', label: 'Los Angeles', trigger: 'end' },
      { value: 'subject2Item', label: 'Las Vegas', trigger: 'end' },
      { value: 'subject3Item', label: 'San Francisco', trigger: 'end' },
    ],
  },
  {
    id: 'subject3ItemSelected',
    options: [
      { value: 'subject1Item', label: 'Los Angeles', trigger: 'end' },
      { value: 'subject2Item', label: 'Las Vegas', trigger: 'end' },
      { value: 'subject3Item', label: 'San Francisco', trigger: 'end' },
    ],
  },

  {
    id: 'subject4Selected',
    message: 'You selected Assunto 4 as your subject. How can I assist you further?',
    trigger: '2',
  },
  {
    id: '2',
    message: `What subject are you interested in?`,
    trigger: 'subject',
  },
  {
    id: 'end',
    message: 'Thanks for using our services!',
    end: true,
  },
];

// Creating our own theme
const theme = {
  background: '#ffffff',
  headerBgColor: '#f6911e',
  headerFontSize: '20px',
  botBubbleColor: '#1D2B36',
  headerFontColor: 'white',
  botFontColor: 'white',
  userBubbleColor: '#58595b',
  userFontColor: 'white',
};
const config = {
    botAvatar: images.avatar_chatbot,
    floating: true,
    reactStrictMode: false,
    swcMinify: true,

};

const ChatBotBramer = () => {
    return (
        <ThemeProvider theme={theme}>
            <ChatBot
                headerTitle="Bramer"
                steps={steps}
                {...config}

            />
        </ThemeProvider>
    );
}

const App = () => {
  return (
    <div >
      <Menu />
      <Hero />
      <AboutUs />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
