export default function IconValley({ fillColor }) {
  return (
    <svg height="54" width="54" version="1.1" id="Capa_1"
	 viewBox="0 0 448.391 448.391">
<g>
	<path d="M444.484,288.919c-34.212-10.367-69.064-18.237-104.319-23.59c15.194-15.27,22.361-32.275,20.814-49.701
		c-1.772-19.927-20.767-36.352-56.47-48.878c46.341,4.842,92.116,14.063,136.785,27.598c0.532,0.161,1.068,0.238,1.597,0.238
		c2.36,0,4.542-1.531,5.262-3.907c0.881-2.907-0.762-5.978-3.669-6.858c-38.015-11.519-76.818-19.958-116.087-25.282l26.023-22.793
		l13.738,0.858c1.102,0.069,2.193-0.194,3.141-0.754l44-26c2.615-1.545,3.482-4.918,1.937-7.533
		c-1.545-2.615-4.917-3.484-7.533-1.937l-42.545,25.14l-14.313-0.895c-1.452-0.091-2.875,0.396-3.967,1.352l-35.083,30.728
		c-18.12-2.094-36.331-3.523-54.6-4.291l7.097-8.964l13.553-0.847c1.309-0.082,2.546-0.629,3.487-1.542l32.22-31.273l10.527,0.81
		c1.825,0.138,3.599-0.636,4.734-2.07l17.349-21.914h24.339c0.866,0,1.721-0.205,2.493-0.597l59-30
		c2.708-1.377,3.787-4.688,2.41-7.396s-4.688-3.785-7.396-2.41l-57.825,29.403h-25.682c-1.681,0-3.269,0.769-4.313,2.086
		l-17.184,21.706l-10.082-0.776c-1.568-0.122-3.121,0.439-4.252,1.537l-32.542,31.584l-13.971,0.873
		c-1.557,0.097-3,0.852-3.969,2.075l-13.679,17.276c-5.919-0.14-11.842-0.229-17.771-0.229c-21.258,0-42.461,0.906-63.559,2.7
		l-33.404-24.752c-1.354-1.004-3.094-1.33-4.722-0.887l-19.567,5.336l-40.91-27.59c-2.398-1.617-5.641-1.099-7.417,1.184
		l-10.961,15.226L7.419,107.96c-2.844-1.059-6.013,0.389-7.073,3.237c-1.059,2.847,0.39,6.013,3.237,7.073l37.412,14.124
		l-7.234,10.933c-1.54,2.618-0.666,5.989,1.952,7.529c0.876,0.515,1.836,0.76,2.783,0.76c1.885,0,3.721-0.97,4.746-2.712
		l20.445-30.355l38.739,26.126c1.327,0.895,2.979,1.167,4.522,0.746l19.424-5.297l21.455,15.898
		c-46.352,4.969-92.137,14.256-136.832,27.799c-2.907,0.881-4.55,3.952-3.669,6.858c0.881,2.908,3.956,4.551,6.858,3.669
		c54.1-16.393,109.82-26.467,166.207-30.083c87.587,7.265,113.748,27.326,121.545,38.648c4.554,6.613,4.299,12.171,3.284,15.669
		c-6.531,22.495-48.82,35.212-65.231,38.371c-4.081-0.066-8.164-0.105-12.249-0.105c-50.443,0-100.573,5.072-149.74,15.116v-7.515
		c13.453-1.028,26.813-6.787,26.813-31.507c0-26.129-14.188-46.596-32.301-46.596s-32.301,20.467-32.301,46.596
		c0,24.705,13.344,30.472,26.789,31.505v9.856c-18.83,4.144-37.511,9.012-56.005,14.616c-2.907,0.881-4.55,3.952-3.669,6.858
		c0.881,2.907,3.956,4.55,6.858,3.669c17.447-5.287,35.063-9.914,52.815-13.882v14.55c0,3.038,2.462,5.5,5.5,5.5s5.5-2.462,5.5-5.5
		v-16.917c15.206-3.155,30.507-5.825,45.883-8.011c-19.962,15.569-40.29,36.936-60.658,63.8
		c-19.86,26.193-32.001,47.521-32.508,48.417c-1.498,2.643-0.569,5.998,2.074,7.496c0.856,0.485,1.788,0.716,2.707,0.716
		c1.916,0,3.777-1.003,4.79-2.789c0.48-0.847,48.433-84.636,107.036-120.592c6.142-0.673,12.294-1.268,18.455-1.786
		c-4.234,3.828-8.473,7.955-12.69,12.368c-2.099,2.196-2.02,5.677,0.176,7.776c1.065,1.019,2.433,1.524,3.799,1.524
		c1.45,0,2.897-0.569,3.977-1.7c7.837-8.2,15.723-15.349,23.466-21.299c6.356-0.372,12.72-0.662,19.091-0.869
		c-15.81,20.186-51.091,70.525-64.472,132.71c-0.639,2.97,1.25,5.895,4.22,6.534c0.39,0.084,0.779,0.124,1.163,0.124
		c2.536,0,4.816-1.764,5.372-4.344c14.884-69.167,59.31-124.783,68.173-135.354c3.228-0.042,6.457-0.071,9.688-0.071
		c72.506,0,144.356,10.632,213.555,31.6c0.532,0.161,1.068,0.238,1.597,0.238c2.36,0,4.542-1.531,5.262-3.907
		C449.034,292.87,447.392,289.8,444.484,288.919z M51.211,232.941c0-19.295,9.755-35.596,21.301-35.596s21.301,16.301,21.301,35.596
		c0,15.065-5.321,19.531-15.813,20.482v-19.822l6.252-10.715c1.531-2.624,0.645-5.991-1.979-7.522
		c-2.625-1.532-5.992-0.645-7.522,1.979l-2.058,3.527l-2.349-4.363c-1.439-2.675-4.774-3.675-7.45-2.236
		c-2.675,1.44-3.676,4.775-2.236,7.45L67,233.501v1.771l-4.616-2.841c-2.587-1.592-5.975-0.786-7.567,1.801
		c-1.592,2.587-0.786,5.975,1.801,7.567L67,248.188v5.233C56.524,252.465,51.211,247.994,51.211,232.941z M315.783,221.648
		c2.374-8.175,0.673-17.044-4.788-24.975c-10.199-14.811-31.681-24.836-56.742-31.623c34.918,6.325,93.215,22.808,95.769,51.55
		c1.456,16.412-6.589,32.124-23.917,46.729c-17.428-2.308-34.946-4.001-52.527-5.077
		C291.263,251.059,310.639,239.367,315.783,221.648z"/>
	<path d="M145.319,299.624c-2.35-1.925-5.815-1.581-7.74,0.769c-8.279,10.104-16.556,21.38-24.602,33.513
		c-1.679,2.532-0.987,5.945,1.544,7.624c0.935,0.62,1.991,0.917,3.035,0.917c1.782,0,3.53-0.865,4.589-2.461
		c7.841-11.826,15.896-22.801,23.942-32.621C148.013,305.015,147.669,301.55,145.319,299.624z"/>
	<path d="M108.114,351.711c-2.608-1.561-5.984-0.712-7.544,1.893c-12.476,20.835-19.6,35.951-19.897,36.584
		c-1.291,2.75-0.107,6.024,2.642,7.315c0.755,0.354,1.55,0.522,2.333,0.522c2.066,0,4.046-1.17,4.983-3.164
		c0.07-0.15,7.207-15.284,19.376-35.606C111.567,356.649,110.72,353.271,108.114,351.711z"/>
	<path d="M372.459,232.466c2.761,0,5-2.239,5-5v-13.985c4.177-2.565,6.958-8.323,6.958-15.366c0-9.585-5.141-16.814-11.958-16.814
		c-6.817,0-11.958,7.229-11.958,16.814c0,7.043,2.781,12.801,6.958,15.366v13.985C367.459,230.227,369.698,232.466,372.459,232.466z
		 M372.459,191.456c0.738,0.722,1.958,3.043,1.958,6.659s-1.22,5.937-1.958,6.658c-0.737-0.722-1.958-3.043-1.958-6.658
		S371.721,192.178,372.459,191.456z"/>
	<path d="M90.491,77.13c0.125,2.667,2.324,4.766,4.995,4.766h63.2c2.43,0,4.508-1.747,4.926-4.14
		c0.308-1.763,0.463-3.524,0.463-5.236c0-13.438-8.344-23.963-18.995-23.963c-2.304,0-4.58,0.529-6.709,1.535
		c-4.084-5.858-10.003-9.315-16.366-9.315c-8.482,0-15.922,5.934-19.574,15.361c-7.024,2.404-11.98,10.277-11.98,19.787
		c0,0.342,0.015,0.679,0.032,1.015L90.491,77.13z M106.655,65.394c2.085-0.162,3.849-1.604,4.421-3.616
		c1.874-6.581,6.265-11.002,10.928-11.002c4.006,0,7.863,3.269,10.065,8.53c0.647,1.546,2.025,2.665,3.67,2.98
		c1.647,0.315,3.34-0.214,4.512-1.41c1.038-1.059,2.717-2.32,4.828-2.32c4.741,0,8.767,6.046,8.986,13.34h-53.101
		C101.99,68.076,104.384,65.571,106.655,65.394z"/>
	<path d="M231.481,105.352c0.127,2.666,2.325,4.762,4.994,4.762h44.105c2.43,0,4.508-1.747,4.925-4.14
		c0.23-1.315,0.346-2.632,0.346-3.914c0-10.225-6.486-18.234-14.767-18.234c-1.422,0-2.828,0.251-4.173,0.736
		c-3.092-3.895-7.355-6.165-11.93-6.165c-6.257,0-11.936,4.335-14.804,11.047c-5.156,2.125-8.729,7.985-8.729,14.992
		c0,0.267,0.011,0.53,0.025,0.792L231.481,105.352z M244.387,98.594c2.084-0.162,3.848-1.604,4.421-3.616
		c1.103-3.875,3.642-6.581,6.174-6.581c2.176,0,4.333,1.926,5.63,5.025c0.647,1.545,2.024,2.664,3.67,2.979
		c1.645,0.318,3.339-0.213,4.512-1.409c0.522-0.532,1.343-1.167,2.291-1.167c1.928,0,4.051,2.584,4.62,6.289h-33.147
		C243.127,99.185,243.818,98.638,244.387,98.594z"/>
	<path d="M277.501,237.275c2.761,0,5-2.239,5-5v-11.036c4.781-2.56,8.076-8.72,8.076-16.098c0-9.744-5.742-17.376-13.072-17.376
		c-7.33,0-13.071,7.633-13.071,17.376c0,7.374,3.291,13.532,8.067,16.094v11.04C272.501,235.036,274.74,237.275,277.501,237.275z
		 M277.505,197.763c0.79,0,3.072,2.536,3.072,7.376s-2.282,7.377-3.072,7.377s-3.071-2.537-3.071-7.377
		S276.716,197.763,277.505,197.763z"/>
	<path d="M245.501,252.775c3.038,0,5.5-2.462,5.5-5.5v-12.362c8.031-2.289,9.39-9.685,9.39-14.407
		c0-11.154-6.539-19.891-14.885-19.891c-8.347,0-14.886,8.737-14.886,19.891c0,4.72,1.358,12.111,9.381,14.404v12.365
		C240.001,250.312,242.464,252.775,245.501,252.775z M245.506,211.615c1.127,0,3.885,3.116,3.885,8.891c0,3.238,0,4.109-3.885,4.109
		c-3.886,0-3.886-0.871-3.886-4.109C241.621,214.73,244.378,211.615,245.506,211.615z"/>
</g>
</svg>
  );
}
