export default function IconBridgeEngineering({ fillColor }) {
  return (
    <svg fill="#000000" height="54" width="54" version="1.1" id="Layer_1"
    viewBox="0 0 512 512">
 <g>
   <g>
     <path d="M495.302,108.533c-9.22,0-16.695,7.475-16.695,16.695v8.607c-21.429-14.398-45.853-26.576-72.347-36.221V75.348
       c0-9.22-7.475-16.695-16.695-16.695c-9.22,0-16.695,7.475-16.695,16.695v11.742c-31.701-8.516-65.544-13.623-100.172-14.872
       V55.209c0-9.22-7.475-16.695-16.695-16.695c-9.22,0-16.695,7.475-16.695,16.695v17.009c-34.628,1.249-68.471,6.357-100.172,14.872
       V75.348c0-9.22-7.475-16.695-16.695-16.695s-16.695,7.475-16.695,16.695v22.266c-26.493,9.645-50.918,21.823-72.347,36.221v-8.607
       c0-9.22-7.475-16.695-16.695-16.695c-9.22,0-16.695,7.475-16.695,16.695c0,5.271,0,176.729,0,189.109
       c0,6.428,3.69,12.284,9.489,15.059c33.846,16.198,74.273,13.323,105.509-7.501c24.8-16.533,56.852-16.531,81.651,0
       c36.049,24.032,82.637,24.039,118.694,0c24.802-16.533,56.852-16.532,81.651,0c31.235,20.824,71.664,23.7,105.51,7.501
       c5.798-2.775,9.489-8.631,9.489-15.059c0-6.183,0-166.456,0-189.109C511.998,116.008,504.523,108.533,495.302,108.533z
        M272.697,105.617c34.942,1.343,68.876,6.893,100.172,16.073v35.544c-31.018-10.469-65.012-16.684-100.172-18.184V105.617z
        M139.134,121.69c31.295-9.18,65.23-14.73,100.172-16.073v33.434c-35.161,1.499-69.155,7.714-100.172,18.184V121.69z
        M33.396,175.401c20.286-16.811,44.863-31.01,72.347-42.137v37.453c-9.277,4.412-18.178,9.244-26.621,14.499
       c-18.265,11.367-33.573,24.153-45.725,37.982V175.401z M296.826,294.113c-24.798,16.534-56.849,16.533-81.651,0
       c-18.025-12.017-38.687-18.025-59.347-18.025c-0.292,0-0.584,0.013-0.876,0.016c24.767-25.302,64.242-37.245,101.048-37.245
       c37.082,0,76.436,12.1,101.049,37.246C336.098,275.932,315.108,281.927,296.826,294.113z M415.521,294.113
       c-4.054-2.702-8.245-5.083-12.536-7.178c-13.876-40.749-70.587-81.468-146.984-81.468c-76.396,0-133.107,40.719-146.983,81.468
       c-4.291,2.095-8.483,4.477-12.537,7.179c-18.62,12.413-41.989,15.556-63.042,8.924c1.119-33.008,23.506-64.69,63.326-89.473
       C139.128,187.2,197.168,172.078,256,172.078s116.872,15.122,159.236,41.488c39.818,24.783,62.206,56.464,63.326,89.473
       C457.508,309.668,434.141,306.527,415.521,294.113z M478.607,223.197c-12.152-13.828-27.462-26.614-45.725-37.981
       c-8.443-5.256-17.344-10.087-26.621-14.499v-37.453c27.484,11.127,52.061,25.325,72.347,42.137V223.197z"/>
   </g>
 </g>
 <g>
   <g>
     <path d="M510.362,373.913c-3.979-8.318-13.946-11.835-22.267-7.854c-23.277,11.139-51.087,9.16-72.575-5.164
       c-36.052-24.035-82.642-24.035-118.694,0c-24.798,16.534-56.849,16.533-81.65,0c-36.052-24.035-82.642-24.035-118.694,0
       c-21.488,14.324-49.298,16.302-72.574,5.164c-8.32-3.98-18.286-0.464-22.267,7.854c-3.98,8.318-0.464,18.286,7.854,22.266
       c33.846,16.198,74.273,13.323,105.509-7.501c24.802-16.533,56.852-16.532,81.651,0c36.049,24.032,82.637,24.039,118.694,0
       c24.8-16.533,56.85-16.533,81.651,0c31.235,20.824,71.664,23.701,105.51,7.501C510.826,392.199,514.341,382.231,510.362,373.913z"
       />
   </g>
 </g>
 <g>
   <g>
     <path d="M510.362,440.695c-3.979-8.318-13.946-11.836-22.267-7.854c-23.277,11.138-51.087,9.16-72.575-5.164
       c-36.052-24.035-82.642-24.035-118.694,0c-24.798,16.534-56.849,16.533-81.65,0c-36.052-24.035-82.642-24.035-118.694,0
       C74.992,442,47.182,443.979,23.907,432.841c-8.32-3.98-18.286-0.463-22.267,7.854c-3.98,8.318-0.464,18.286,7.854,22.266
       c33.846,16.197,74.273,13.323,105.509-7.501c24.802-16.533,56.852-16.533,81.651,0c36.049,24.032,82.637,24.039,118.694,0
       c24.8-16.533,56.85-16.533,81.651,0c31.235,20.825,71.664,23.7,105.51,7.501C510.826,458.981,514.341,449.012,510.362,440.695z"/>
   </g>
 </g>
 </svg>
  );
}
