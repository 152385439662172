export default function IconOther({ fillColor }) {
  return (
<svg fill="#000000" version="1.1" id="Capa_1" 
	 width="54" height="54" viewBox="0 0 50.483 50.483"
>
<g>
	<g>
		<polygon points="32.871,22.007 32.9,22.225 32.574,22.292 32.531,22.757 32.922,22.757 33.443,22.706 33.711,22.387 
			33.426,22.276 33.27,22.096 33.033,21.716 32.922,21.177 32.479,21.266 32.357,21.458 32.357,21.671 32.57,21.815 		"/>
		<polygon points="32.496,22.186 32.52,21.895 32.261,21.782 31.897,21.866 31.629,22.298 31.629,22.577 31.943,22.577 		"/>
		<path d="M25.035,23.423l-0.08,0.205h-0.386v0.199h0.092c0,0,0.006,0.041,0.013,0.098l0.237-0.02l0.147-0.092l0.039-0.187
			l0.191-0.018l0.075-0.156l-0.176-0.035L25.035,23.423z"/>
		<polygon points="24.039,23.784 24.025,23.979 24.305,23.956 24.333,23.759 24.166,23.628 		"/>
		<path d="M13.751,24.466v0.42c0.146-0.344,0.308-0.678,0.481-1.006L13.751,24.466z"/>
		<path d="M50.469,11.907c-0.13-2.244-1.126-4.303-2.807-5.799c-1.543-1.375-3.528-2.131-5.594-2.131
			c-2.4,0-4.693,1.027-6.289,2.818c-2.084,2.342-2.692,5.586-1.611,8.516c-0.032,0.783-0.115,1.627-0.213,2.41
			c-2.262-1.424-4.937-2.248-7.801-2.248c-3.833,0-7.326,1.477-9.943,3.891c-0.61,0.561-1.171,1.176-1.679,1.832
			c-1.919,2.482-3.064,5.591-3.064,8.964c0,0.031,0.004,0.062,0.005,0.093c-1.104-0.431-2.305-0.638-3.542-0.568
			c-2.245,0.13-4.305,1.125-5.8,2.806c-1.495,1.68-2.247,3.84-2.116,6.084c0.256,4.447,3.943,7.932,8.396,7.932
			c0.163,0,0.327-0.006,0.492-0.016c3.132-0.183,5.855-2.045,7.16-4.881c0.145-0.134,0.295-0.267,0.448-0.398
			c2.583,2.258,5.952,3.639,9.646,3.639c5.695,0,10.643-3.264,13.076-8.016c0.521-1.016,0.926-2.1,1.199-3.234
			c0.068-0.287,0.129-0.578,0.182-0.871c0.147-0.834,0.229-1.691,0.229-2.566c0-0.051-0.004-0.102-0.004-0.151
			c-0.005-0.431-0.026-0.854-0.067-1.274c-0.136-1.4-0.471-2.742-0.973-4c-0.037-0.094-0.072-0.188-0.11-0.279
			c-0.516-1.217-1.192-2.346-2.001-3.369c0.789-0.203,1.604-0.404,2.264-0.543c0.688,0.178,1.397,0.27,2.112,0.27
			c2.399,0,4.691-1.027,6.288-2.82C49.847,16.313,50.599,14.151,50.469,11.907z M18.235,19.946v-0.025h0.294l0.025-0.102h0.481
			v0.211l-0.138,0.184h-0.663L18.235,19.946L18.235,19.946z M18.705,20.6c0,0,0.294-0.051,0.319-0.051c0.025,0,0,0.295,0,0.295
			l-0.664,0.041l-0.126-0.15L18.705,20.6z M14.548,40.286l-0.151,0.144l-0.083,0.19c-0.966,2.248-3.084,3.732-5.526,3.873
			c-3.547,0.209-6.573-2.52-6.776-6.035c-0.099-1.711,0.474-3.358,1.614-4.639c1.14-1.279,2.71-2.039,4.421-2.139
			c0.126-0.008,0.251-0.012,0.376-0.012c2.34,0,4.495,1.279,5.624,3.336l0.118,0.213l0.203,0.137
			c0.98,0.656,2.487,1.539,3.584,2.168C16.977,38.235,15.632,39.268,14.548,40.286z M39.271,27.061
			c0.019,0.076,0.036,0.152,0.054,0.23l-0.096-0.18L39.271,27.061z M38.074,24.137l0.086-0.096c0.11,0.219,0.218,0.438,0.315,0.662
			l-0.139-0.006l-0.263,0.035V24.137L38.074,24.137z M38.721,25.294c0.137,0.35,0.258,0.705,0.365,1.068L39.01,26.43h-1.075
			l-0.655-0.486l-0.688,0.066v0.42h-0.221l-0.234-0.168l-1.193-0.303v-0.773l-1.512,0.119l-0.47,0.252h-0.601l-0.295-0.029
			l-0.729,0.404v0.762l-1.49,1.076l0.124,0.459h0.302l-0.078,0.436l-0.213,0.078l-0.011,1.146l1.287,1.468h0.562l0.033-0.091h1.008
			l0.291-0.271h0.571l0.313,0.314l0.852,0.088l-0.111,1.133l0.945,1.668l-0.498,0.953l0.033,0.447l0.393,0.393v1.078l0.516,0.691
			v0.896h0.445c-2.475,3.037-6.242,4.981-10.455,4.981c-3.319,0-6.354-1.217-8.706-3.215c1.468-1.181,2.906-2.154,2.926-2.168
			l1.348-0.912l-1.422-0.791c-0.031-0.017-2.984-1.66-4.627-2.731c-0.733-1.246-1.766-2.244-2.966-2.957
			c-0.016-0.232-0.032-0.467-0.032-0.703c0-1.871,0.384-3.654,1.076-5.275v-0.42l0.481-0.586c0.167-0.316,0.347-0.623,0.538-0.924
			l0.021,0.244l-0.56,0.68c-0.174,0.328-0.335,0.662-0.481,1.006v0.766l0.56,0.27v1.065l0.538,0.916l0.437,0.068l0.056-0.314
			l-0.515-0.795l-0.101-0.772h0.302l0.128,0.795l0.745,1.088l-0.193,0.353l0.474,0.723l1.176,0.291v-0.189l0.471,0.066l-0.045,0.336
			l0.37,0.068l0.571,0.156l0.807,0.92l1.03,0.076l0.101,0.842l-0.705,0.492l-0.033,0.75l-0.102,0.459l1.02,1.276l0.078,0.438
			c0,0,0.37,0.1,0.415,0.1c0.045,0,0.828,0.594,0.828,0.594v2.309l0.28,0.078l-0.19,1.064l0.471,0.627l-0.087,1.055l0.622,1.094
			l0.797,0.695l0.803,0.016l0.079-0.258l-0.59-0.496l0.034-0.246l0.106-0.303l0.022-0.309l-0.399-0.01l-0.2-0.254l0.328-0.319
			l0.045-0.24l-0.369-0.106l0.021-0.223l0.527-0.08l0.801-0.386l0.269-0.494l0.841-1.073l-0.191-0.842l0.258-0.447l0.772,0.022
			l0.521-0.412l0.168-1.62l0.576-0.734l0.102-0.471l-0.525-0.168l-0.348-0.57l-1.188-0.012L25.58,32.25l-0.044-0.67l-0.313-0.551
			l-0.852-0.013l-0.493-0.771l-0.437-0.213l-0.022,0.233l-0.795,0.048l-0.291-0.404l-0.83-0.17l-0.684,0.789l-1.075-0.182
			l-0.078-1.213l-0.785-0.133l0.315-0.596l-0.091-0.342l-1.031,0.688l-0.648-0.078l-0.231-0.509l0.142-0.52l0.358-0.66l0.822-0.416
			l1.59-0.002l-0.004,0.485l0.571,0.267l-0.046-0.828l0.412-0.414l0.831-0.547l0.057-0.385l0.829-0.861l0.882-0.488l-0.078-0.064
			l0.597-0.561l0.219,0.057l0.1,0.127l0.227-0.252l0.056-0.025l-0.247-0.033l-0.252-0.084v-0.244l0.134-0.109h0.294l0.135,0.059
			l0.117,0.236l0.143-0.021v-0.021l0.041,0.014l0.413-0.064l0.059-0.201l0.235,0.059v0.219l-0.219,0.15h0.001l0.031,0.24l0.748,0.23
			c0,0,0.002,0.004,0.003,0.01l0.173-0.016l0.01-0.324l-0.592-0.27l-0.033-0.156l0.493-0.168l0.021-0.471l-0.514-0.313l-0.034-0.795
			l-0.706,0.346h-0.257l0.067-0.604l-0.961-0.229l-0.397,0.301v0.916l-0.715,0.227l-0.286,0.596l-0.311,0.051v-0.764l-0.672-0.094
			l-0.335-0.219l-0.136-0.494l1.202-0.703l0.588-0.18l0.059,0.395l0.328-0.016l0.024-0.199l0.344-0.049l0.006-0.07l-0.147-0.061
			l-0.034-0.209L24,19.912l0.255-0.266l0.015-0.021l0.003,0.002l0.077-0.08l0.885-0.111l0.392,0.332l-1.026,0.547l1.306,0.307
			l0.169-0.436h0.571l0.201-0.381l-0.403-0.102v-0.48l-1.266-0.561l-0.873,0.102l-0.494,0.256l0.034,0.629l-0.515-0.08l-0.08-0.346
			l0.494-0.449L22.85,18.73l-0.257,0.078l-0.112,0.303l0.336,0.057l-0.067,0.336l-0.571,0.033l-0.09,0.225l-0.828,0.021
			c0,0-0.023-0.469-0.057-0.469c-0.034,0,0.649-0.012,0.649-0.012l0.493-0.482l-0.269-0.135l-0.358,0.348L21.125,19l-0.359-0.492
			h-0.764l-0.795,0.594h0.728l0.067,0.213l-0.19,0.178l0.807,0.023l0.123,0.291l-0.908-0.033l-0.044-0.225l-0.571-0.123
			l-0.303-0.168l-0.68,0.006c2.226-1.621,4.962-2.582,7.92-2.582c2.826,0,5.444,0.887,7.614,2.381
			c-0.146,0.969-0.276,1.654-0.28,1.668l-0.309,1.598l1.564-0.445c0.007-0.002,0.17-0.049,0.429-0.121l0.055,0.385h-0.167
			l-0.455-0.066l-0.503,0.084l-0.487,0.689l-0.696,0.109l-0.103,0.596l0.295,0.07l-0.084,0.383l-0.693-0.139l-0.635,0.139
			l-0.135,0.354l0.109,0.742l0.374,0.174l0.624-0.004l0.422-0.039l0.13-0.336l0.659-0.857l0.436,0.09l0.426-0.387l0.08,0.303
			l1.054,0.709l-0.13,0.174l-0.475-0.025l0.184,0.258l0.291,0.064l0.343-0.143l-0.006-0.412l0.151-0.076l-0.121-0.129l-0.703-0.393
			l-0.185-0.52h0.583L36,23.746l0.503,0.432l0.021,0.525l0.521,0.555l0.193-0.76l0.36-0.197l0.067,0.621l0.353,0.387L38.721,25.294z
			 M19.478,22.145l0.354-0.168l0.319,0.076l-0.109,0.43l-0.345,0.109L19.478,22.145z M21.351,23.155v0.277h-0.806l-0.303-0.084
			l0.076-0.193l0.387-0.16h0.528v0.16H21.351z M21.721,23.542v0.268l-0.201,0.131l-0.252,0.047c0,0,0-0.404,0-0.445L21.721,23.542
			L21.721,23.542z M21.494,23.432v-0.32l0.277,0.252L21.494,23.432z M21.62,24.077v0.262l-0.193,0.193h-0.428l0.066-0.293
			l0.202-0.018l0.042-0.102L21.62,24.077z M20.554,23.542h0.445l-0.571,0.797l-0.236-0.127l0.05-0.334L20.554,23.542z
			 M22.375,23.987v0.26h-0.429l-0.116-0.17v-0.242h0.033L22.375,23.987z M21.982,23.628l0.122-0.129l0.206,0.129l-0.165,0.135
			L21.982,23.628z M36.619,21.677l-0.265,0.395l-0.925-0.01l-0.059-0.191l0.58-0.295v-0.029c0.141-0.039,0.291-0.078,0.445-0.121
			C36.472,21.509,36.549,21.591,36.619,21.677z M46.857,16.661c-1.623,1.822-4.259,2.572-6.593,1.895l-0.233-0.068l-0.24,0.047
			c-1.029,0.205-2.467,0.572-3.635,0.885c-0.041-0.037-0.08-0.08-0.121-0.117c-0.08-0.072-0.168-0.135-0.248-0.205
			c0.17-1.148,0.347-2.602,0.389-3.922l0.007-0.207l-0.077-0.193c-0.904-2.273-0.457-4.82,1.17-6.648
			C38.492,6.763,40.24,5.98,42.07,5.98c1.573,0,3.088,0.576,4.264,1.625c1.281,1.139,2.041,2.709,2.14,4.422
			C48.572,13.735,47.998,15.382,46.857,16.661z"/>
		<g>
			<path d="M8.479,38.792c1.563,0,2.833-1.168,2.881-2.618l0.043,0.021c0,0,0.188-0.41,0.085-0.992s-0.598-2.568-3.009-2.467
				c-2.36-0.064-1.859,0.883-2.406,1.02c-1.012,0.041-0.563,2.15-0.455,2.605C5.765,37.721,6.983,38.792,8.479,38.792z
				 M10.822,36.091c0,1.188-1.052,2.153-2.343,2.153c-1.271,0-2.304-0.938-2.337-2.1L8.39,34.79l2.412,1.123
				C10.808,35.971,10.822,36.028,10.822,36.091z"/>
			<path d="M11.239,38.657l-1.374,0.445l-1.118,0.943l-1.14-0.848l-1.545-0.541c0,0-0.95,4.024-0.062,4.024c0.888,0,4.555,0,5.457,0
				C12.361,42.682,11.239,38.657,11.239,38.657z"/>
		</g>
		<g>
			<path d="M41.839,13.495c1.563,0,2.833-1.168,2.881-2.619l0.043,0.021c0,0,0.188-0.412,0.085-0.992
				c-0.104-0.582-0.598-2.57-3.009-2.467c-2.36-0.064-1.859,0.881-2.406,1.018c-1.012,0.041-0.563,2.152-0.455,2.605
				C39.125,12.425,40.343,13.495,41.839,13.495z M44.182,10.794c0,1.188-1.052,2.154-2.343,2.154c-1.271,0-2.304-0.938-2.337-2.098
				l2.248-1.355l2.412,1.121C44.168,10.677,44.182,10.733,44.182,10.794z"/>
			<path d="M44.599,13.36l-1.374,0.447l-1.117,0.941l-1.141-0.846l-1.545-0.543c0,0-0.95,4.025-0.062,4.025c0.889,0,4.555,0,5.457,0
				C45.721,17.386,44.599,13.36,44.599,13.36z"/>
		</g>
	</g>
</g>
</svg>
  );
}
