import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { images } from "../../constants";
import { Box } from "@mui/material";


const ServiceCard = ({ urlImage, title, description, children }) => {
  return (
    <div style={{ margin: "5%",height:'90%' }}>
      <Card
        sx={{ height:'100%',maxWidth: 385, elevation: 0, marginBottom: 10}}
        >
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <CardHeader title={title} subheader="" />
        </Box>

        <CardContent sx={{ elevation: 0 }}>
          <Box
            bgcolor="black"
            style={{
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              overflow: "hidden",
            }}
          >
            <img
              className="img-fluid"
              src={images[urlImage]}
              alt="design"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        </CardContent>
        <CardActions disableSpacing>
        </CardActions>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{description}</Typography>
          </CardContent>
          <CardActions disableSpacing>
          </CardActions>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <CardContent>{children}</CardContent>
          </Collapse>
        </Collapse>
      </Card>
    </div>
  );
};
export default ServiceCard;
