
const Menu = [
    {
        text: 'menu_home',
        link: '#home',
    },
    {
        text: 'menu_about_us',
        link: '#about-us',
    },
    {
        text: 'menu_services',
        link: '#services',
    },

    {
        text: 'menu_contact_us',
        link: '#quote',
    }
];

const data = { Menu };

export default data;
 