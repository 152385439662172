import { Button, Typography } from "@mui/material";

const ServiceButtonCard = ({ text, onClick }) => {
  return (
    <Button variant="text" onClick={onClick} sx={{ display: 'flex', alignItems: 'center' ,color:'black'}}>
      <Typography paragraph>{text}</Typography>
    </Button>
  );
};

export default ServiceButtonCard;