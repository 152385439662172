export default function IconHelicopter({ fillColor }) {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.12 16.1736L43.9266 20.6241L42.0735 21.3763L40.4089 17.2758L30.0838 18.1443L29.9162 16.1513L40.3046 15.2775L42.0455 9.70215L43.9546 10.2983L42.12 16.1736Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.93285 30.7229C8.85162 30.3748 8.76156 29.8749 8.72792 29.2748C8.65959 28.0561 8.82489 26.4565 9.73614 24.8631C11.4841 21.8065 16.2675 18.3267 28.9943 18.1528C28.9499 21.8571 28.6275 24.9512 27.3687 27.175C26.7031 28.3509 25.7707 29.2875 24.4362 29.9416C23.0866 30.6032 21.2749 31 18.8268 31C13.7341 31 11.0911 30.8968 9.73115 30.7964C9.38161 30.7705 9.12267 30.7453 8.93285 30.7229ZM8.13937 31.5873L7.4919 32.3493C7.36109 32.2382 7.26109 32.0953 7.20152 31.9343L8.13937 31.5873ZM7.99998 23.8702C10.3359 19.7854 16.2782 16.146 30 16.146C30.5523 16.146 31 16.5937 31 17.146C31 21.2466 30.7743 25.2186 29.1092 28.1602C28.2567 29.6662 27.0335 30.8958 25.3165 31.7374C23.6148 32.5716 21.4794 33 18.8268 33C13.7178 33 11.0172 32.8968 9.58379 32.7909C8.86941 32.7381 8.44748 32.6832 8.18915 32.6344C8.06041 32.6101 7.9519 32.5836 7.8584 32.5518C7.81074 32.5356 7.75667 32.5146 7.70027 32.4864C7.6715 32.472 7.63929 32.4544 7.60523 32.4328C7.58806 32.4219 7.5699 32.4096 7.55108 32.3959C7.54165 32.3891 7.53198 32.3818 7.52212 32.374L7.50717 32.3621L7.49957 32.3558C7.49572 32.3526 7.4919 32.3493 8.13937 31.5873C7.20152 31.9343 7.20122 31.9335 7.2009 31.9326L7.20016 31.9306L7.19831 31.9255L7.19325 31.9114C7.18928 31.9002 7.18415 31.8855 7.178 31.8674C7.16571 31.8313 7.14934 31.7814 7.1301 31.7188C7.09165 31.5936 7.04155 31.4169 6.98952 31.1959C6.88566 30.7549 6.77284 30.1321 6.73106 29.3868C6.64758 27.8978 6.84661 25.8871 7.99998 23.8702Z"
        fill="#333333"
      />
      <path
        d="M4 11C4 10.4477 4.44772 10 5 10H38.0048C38.5571 10 39.0048 10.4477 39.0048 11C39.0048 11.5523 38.5571 12 38.0048 12H5C4.44772 12 4 11.5523 4 11Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.37528 34.2192C4.80654 33.8742 5.43584 33.9441 5.78084 34.3753C6.00173 34.6514 6.56423 34.9759 7.53894 35.2553C8.46303 35.5202 9.57626 35.6956 10.674 35.8089C11.7656 35.9215 12.8098 35.9695 13.5834 35.9892C13.9694 35.999 14.2861 36.0017 14.5051 36.002C14.6146 36.0021 14.6996 36.0017 14.7565 36.0012L14.8205 36.0005L14.8358 36.0003L14.8391 36.0002L14.8483 36H30.5C31.0523 36 31.5 36.4478 31.5 37C31.5 37.5523 31.0523 38 30.5 38H14.865L14.847 38.0003L14.774 38.0011C14.7108 38.0016 14.6192 38.0021 14.5026 38.002C14.2697 38.0017 13.9368 37.9988 13.5326 37.9886C12.7259 37.968 11.6273 37.9179 10.4688 37.7983C9.31655 37.6794 8.07264 37.4888 6.9878 37.1778C5.95358 36.8814 4.85537 36.4201 4.21911 35.6247C3.8741 35.1935 3.94402 34.5642 4.37528 34.2192Z"
        fill="#333333"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 13C22.5523 13 23 13.4477 23 14H22H21C21 13.4477 21.4477 13 22 13ZM9 27C9 26 9.00004 26 9.00011 26H19.5146C19.5765 25.8605 19.6492 25.6654 19.7285 25.4093C19.9534 24.6831 20.1723 23.6659 20.3649 22.5624C20.7525 20.342 21 17.9735 21 17.25V14H22H23V17.25C23 18.1515 22.7275 20.658 22.3351 22.9063C22.1377 24.0372 21.9016 25.1528 21.639 26.0009C21.5099 26.4177 21.3588 26.8223 21.1775 27.1415C21.0878 27.2995 20.964 27.485 20.7939 27.6424C20.6246 27.799 20.3282 28 19.92 28H9.00045C9.00038 28 9 28 9 27ZM9 27L9.00011 26C8.44783 26 8 26.4477 8 27C8 27.5523 8.44817 28 9.00045 28L9 27Z"
        fill="#333333"
      />
    </svg>
  );
}
