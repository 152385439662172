export default function IconMount({ fillColor }) {
  return (
    <svg
      fill="#000000"
      height="54"
      width="54"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 511.988 511.988"
    >
      <g>
        <g>
          <g>
            <path
              d="M117.333,74.661c5.888,0,10.667-4.779,10.667-10.667V42.661c0-5.888-4.779-10.667-10.667-10.667
         s-10.667,4.779-10.667,10.667v21.333C106.667,69.882,111.445,74.661,117.333,74.661z"
            />
            <path
              d="M117.333,202.661c29.397,0,53.333-23.915,53.333-53.333c0-29.419-23.936-53.333-53.333-53.333
         C87.936,95.994,64,119.909,64,149.327C64,178.746,87.936,202.661,117.333,202.661z M117.333,117.327c17.643,0,32,14.357,32,32
         c0,17.643-14.357,32-32,32c-17.643,0-32-14.357-32-32C85.333,131.685,99.691,117.327,117.333,117.327z"
            />
            <path
              d="M177.685,99.663c2.731,0,5.44-1.045,7.531-3.115l15.083-15.083c4.16-4.16,4.16-10.923,0-15.083
         c-4.16-4.16-10.923-4.16-15.083,0l-15.083,15.083c-4.16,4.16-4.16,10.923,0,15.083C172.224,98.618,174.955,99.663,177.685,99.663
         z"
            />
            <path
              d="M192,149.327c0,5.888,4.779,10.667,10.667,10.667H224c5.888,0,10.667-4.779,10.667-10.667s-4.779-10.667-10.667-10.667
         h-21.333C196.779,138.661,192,143.439,192,149.327z"
            />
            <path
              d="M49.429,96.549c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.115c4.16-4.16,4.16-10.923,0-15.083
         L49.429,66.383c-4.16-4.16-10.923-4.16-15.083,0c-4.16,4.16-4.16,10.923,0,15.083L49.429,96.549z"
            />
            <path
              d="M10.667,159.994H32c5.888,0,10.667-4.779,10.667-10.667S37.888,138.661,32,138.661H10.667
         C4.779,138.661,0,143.439,0,149.327S4.779,159.994,10.667,159.994z"
            />
            <path
              d="M509.781,462.842c-20.288-26.453-45.781-45.227-70.443-63.424c-47.552-35.051-96.725-71.296-119.637-167.232
         c-0.683-2.859-2.539-5.333-5.12-6.784c-2.56-1.451-5.589-1.728-8.448-0.917c-0.299,0.107-30.059,9.387-50.56,10.197
         c-20.096,1.109-48.896-9.899-49.195-10.005c-3.029-1.109-6.421-0.789-9.195,0.853c-2.773,1.664-4.629,4.523-5.077,7.723
         c-12.181,90.837-64.811,128.384-115.691,164.693c-26.496,18.901-53.888,38.443-74.197,64.896
         c-2.475,3.221-2.901,7.552-1.109,11.2c1.792,3.648,5.504,5.952,9.557,5.952h490.667c4.053,0,7.765-2.304,9.557-5.952
         S512.256,466.063,509.781,462.842z M256.427,255.994c13.995-0.576,30.635-4.288,42.219-7.296v26.091l-1.131-2.24
         c-1.92-3.84-5.781-6.229-10.304-5.867c-4.288,0.32-7.979,3.2-9.344,7.275l-11.221,33.643l-11.221-33.643
         c-1.152-3.499-4.053-6.165-7.659-6.997c-3.563-0.896-7.36,0.235-9.984,2.837l-9.899,9.899L216,249.957
         C227.627,253.093,243.008,256.229,256.427,255.994z M34.304,458.661c15.936-15.851,34.816-29.312,54.464-43.349
         c44.181-31.509,93.184-66.795,115.264-137.792l10.048,25.109c1.344,3.349,4.245,5.781,7.765,6.507
         c3.563,0.704,7.147-0.405,9.685-2.923l9.131-9.131l15.893,47.616c1.451,4.352,5.525,7.296,10.112,7.296s8.661-2.944,10.091-7.317
         l13.205-39.552l9.813,19.627c2.219,4.416,7.211,6.848,11.989,5.611c4.8-1.109,8.213-5.44,8.213-10.368v-21.675
         c28.181,60.267,69.355,90.731,106.709,118.272c18.283,13.483,35.797,26.411,51.2,42.069H34.304z"
            />
            <path
              d="M49.429,202.127L34.347,217.21c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.069,4.821,3.115,7.552,3.115
         s5.461-1.045,7.531-3.115l15.083-15.083c4.16-4.16,4.16-10.923,0-15.083C60.352,197.967,53.589,197.967,49.429,202.127z"
            />
            <path
              d="M106.667,234.661v21.333c0,5.888,4.779,10.667,10.667,10.667S128,261.882,128,255.994v-21.333
         c0-5.888-4.779-10.667-10.667-10.667S106.667,228.773,106.667,234.661z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
